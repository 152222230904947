import React, { useEffect, useState, useRef, useSyncExternalStore } from "react"
import { useTranslation } from "react-i18next"
import useAuth from "../../hooks/useAuth"

import { dbPutUser } from "../../api/account"

import { Loading } from "../Loading"
import { Button } from "../Button"
import { Title } from "../../component/Title"

import {
  FormInput,
  FormCheckBox,
  FormButton,
  isValidName,
} from "../FormElements"

import "./Settings.css"

export const UserData = () => {
  const { t } = useTranslation()
  const { auth, setAuth } = useAuth()

  const [formChanged, setFormChanged] = useState(false)
  const [formState, setFormState] = useState("FORM")
  const [errorDescription, setErrorDescription] = useState("")

  const firstNameRef = useRef()
  const lastNameRef = useRef()
  const nationalIdRef = useRef()
  const emailRef = useRef()
  const phoneRef = useRef()

  const nationalId = auth?.user?.nationalId
  const email = auth?.user?.email

  const [firstName, setFirstName] = useState(auth.user.firstName)
  const [lastName, setLastName] = useState(auth.user.lastName)
  const [nationalityId, setNationalityId] = useState(auth.user.nationalityId)
  const [phone, setPhone] = useState(auth.user.phone)
  const [marketingOptIn, setMarketingOptIn] = useState(
    auth?.user?.marketingOptIn
  )

  useEffect(() => {
    if (
      firstName != auth.user.firstName ||
      lastName != auth.user.lastName ||
      phone != auth.user.phone ||
      marketingOptIn != auth.user.marketingOptIn
    )
      setFormChanged(true)
    else {
      setFormChanged(false)
    }
  }, [firstName, lastName, marketingOptIn, phone])

  const formIsValid = () => {
    return isValidName(firstName) && isValidName(lastName) && phone.length >= 10
  }

  const resetFields = () => {
    setFirstName(auth.user.firstName)
    setLastName(auth.user.lastName)
    setPhone(auth.user.phone)
    setMarketingOptIn(auth.user.marketingOptIn)
  }

  useEffect(() => {
    resetFields()
  }, [auth])

  const updateUser = async (e) => {
    e.preventDefault()

    setFormState("WAITING")

    if (!formIsValid()) {
      setFormState("ERROR")
      setErrorDescription(t("settings.userData.form.error.VALIDATION"))
      return
    }

    try {
      const userData = {
        firstName: firstName,
        lastName: lastName,
        phone: phone,
        marketingOptIn: marketingOptIn,
      }
      const user = await dbPutUser(auth.user.id, userData, auth.accessToken)
      setAuth({
        user: user,
        roles: auth.roles,
        accessToken: auth.accessToken,
      })
      resetFields()
      setFormState("DONE")
    } catch (err) {
      setFormState("ERROR")
      if (!err?.response) {
        setErrorDescription(t("settings.userData.form.error.NO_RESPONSE"))
      } else if ("error" in err.response.data) {
        setErrorDescription(
          t("settings.userData.form.error." + err.response.data.error.message)
        )
      } else {
        setErrorDescription(t("settings.userData.form.error.UPDATE_ERROR"))
      }
    }
  }

  const Waiting = () => {
    return (
      <div className="userdata-widget">
        <Loading color="white" context="settings" />
      </div>
    )
  }

  const SubmitComplete = () => {
    return (
      <div className="userdata-widget">
        <p className="mb-2">
          {formState == "ERROR"
            ? errorDescription
            : t("settings.userData.form.updated")}
        </p>
        <Button
          buttonStyle="btn--form"
          buttonSize="btn--small"
          onClick={() => {
            setFormState("FORM")
          }}
        >
          {t("settings.userData.button.dismiss")}
        </Button>
      </div>
    )
  }

  const UserDataForm = (
    <form onSubmit={updateUser}>
      {nationalityId === 46 && (
      <FormInput
        id="nationalId"
        label={t("settings.userData.label.nationalId")}
        value={nationalId || ""}
        disabled={true}
        valid={true}
        reference={nationalIdRef}
        type="text"
        setValue={() => {}}
        setFocus={() => {}}
      />)}
      <FormInput
        id="email"
        label={t("settings.userData.label.email")}
        value={email || ""}
        disabled={true}
        valid={true}
        reference={emailRef}
        type="text"
        setValue={() => {}}
        setFocus={() => {}}
      />
      <FormInput
        id="firstName"
        label={t("settings.userData.label.firstName")}
        value={firstName || ""}
        disabled={false}
        valid={isValidName(firstName)}
        reference={firstNameRef}
        type="text"
        setValue={(e) => {
          setFirstName(e)
        }}
        setFocus={() => {}}
      />
      <FormInput
        id="lastName"
        label={t("settings.userData.label.lastName")}
        value={lastName || ""}
        disabled={false}
        valid={isValidName(lastName)}
        reference={lastNameRef}
        type="text"
        setValue={(e) => {
          setLastName(e)
        }}
        setFocus={() => {}}
      />
      <FormInput
        id="phone"
        label={t("settings.userData.label.phone")}
        value={phone || ""}
        disabled={false}
        valid={phone.length >= 10}
        reference={phoneRef}
        type="text"
        setValue={(e) => {
          setPhone(e)
        }}
        setFocus={() => {}}
      />
      <div className="form-group">
        <FormCheckBox
          label={t("settings.userData.label.marketingOptIn")}
          checked={marketingOptIn ? true : false}
          callback={setMarketingOptIn}
        />
      </div>

      <div
        className={
          formChanged ? "form-button-row right" : "form-button-row invisible"
        }
      >
        <FormButton label={t("settings.userData.button.save")} />
      </div>
    </form>
  )

  return (
    <section className="settings-userdata">
      <Title title={t("settings.userData.title")} />

      <div className="userdata">
        <h2 className="settings-paragraph">
          {t("settings.userData.subTitle")}
        </h2>
        <p className="settings-paragraph">{t("settings.userData.paragraph")}</p>
        {formState == "FORM" ? (
          UserDataForm
        ) : formState == "WAITING" ? (
          <Waiting />
        ) : (
          <SubmitComplete />
        )}
      </div>
    </section>
  )
}
