import React from "react"
import { Loading } from "../Loading"
import { useTranslation } from "react-i18next"
import { Button } from "../Button"

const ProfileCardSubmit = ({
  inSubmit,
  message,
  confirmCode,
  successCode,
  errorCode,
  dismiss,
}) => {
  const { t } = useTranslation()

  if (!errorCode && !successCode && !confirmCode)
    return (
      <div className={inSubmit ? "card-in-submit" : "card-in-submit hidden"}>
        <h2>{message && t("home.profile.submit." + message)}</h2>
        <Loading color="white" context="profile-card"></Loading>
      </div>
    )
  else if (confirmCode) {
    return (
      <div className={inSubmit ? "card-in-submit" : "card-in-submit hidden"}>
        <h2>
          {confirmCode &&
            t("home.profile.submit.confirm." + confirmCode + ".headline")}
        </h2>
        <p>
          {confirmCode &&
            t("home.profile.submit.confirm." + confirmCode + ".description")}
        </p>
        <div className="card-in-submit-buttons">
          <Button
            buttonSize="btn--small"
            buttonStyle="btn--form"
            onClick={() => dismiss()}
          >
            {t("home.profile.button.abort")}
          </Button>
          <Button
            buttonSize="btn--small"
            buttonStyle="btn--danger"
            onClick={() => dismiss(confirmCode)}
          >
            {t("home.profile.button.confirm")}
          </Button>
        </div>
      </div>
    )
  } else if (errorCode)
    return (
      <div className={inSubmit ? "card-in-submit" : "card-in-submit hidden"}>
        <h2>
          {errorCode &&
            t("home.profile.submit.error." + errorCode + ".headline")}
        </h2>
        <p>
          {errorCode &&
            t("home.profile.submit.error." + errorCode + ".description")}
        </p>
        <Button
          buttonSize="btn--small"
          buttonStyle="btn--form"
          onClick={() => dismiss(errorCode)}
        >
          {t("home.profile.submit.error." + errorCode + ".button")}
        </Button>
      </div>
    )
  else
    return (
      <div className={inSubmit ? "card-in-submit" : "card-in-submit hidden"}>
        <h2>
          {successCode &&
            t("home.profile.submit.success." + successCode + "Headline")}
        </h2>
        <p>
          {successCode &&
            t("home.profile.submit.success." + successCode + "Description")}
        </p>
        <Button
          buttonSize="btn--small"
          buttonStyle="btn--form"
          onClick={dismiss}
        >
          {t("home.profile.button.close")}
        </Button>
      </div>
    )
}

export default ProfileCardSubmit
