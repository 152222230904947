import axios from "../api/axios"
import useAuth from "./useAuth"

const useLogout = () => {
  const { setAuth } = useAuth()

  const logout = async () => {
    console.log("Logging out: " + setAuth?.user?.email)
    try {
      const response = await axios.get("/v1/auth/logout", {
        withCredentials: true,
      })
      setAuth({})
      localStorage.setItem("loggedIn", JSON.stringify(false))
    } catch (err) {
      console.log("Unable to logout")
    }
  }
  return logout
}

export default useLogout
