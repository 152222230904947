import { t } from "i18next"
import React from "react"
import Navbar from "../component/Navbar"
import { Button } from "../component/Button"
import "./Page.css"

function Missing() {
  return (
    <>
      <Navbar />
      <section id="missing" className="missing">
        <h1 className="mb-3">{t("missing.title")}</h1>
        <Button buttonStyle="btn--form" buttonSize="btn--large" linkTo="/">
          {t("missing.button.return")}
        </Button>
      </section>
    </>
  )
}

export default Missing
