import React, { useState, useEffect } from "react"
import OneTwoModal from "../OneTwoModal"
import { Button } from "../../Button"
import { initOauth } from "../../../api/dexcom"
import useAuth from "../../../hooks/useAuth"
import { useTranslation } from "react-i18next"
import { removeDataSourceForProfile } from "../../../api/data.js"
import { Loading } from "../../Loading"
import useWebSocket, { ReadyState } from "react-use-websocket"

import { BASE_URL } from "../../../api/axios"

export default function Libre({
  libreModalOpen,
  setLibreModalOpen,
  setLibreLoading,
  isLibreLoading,
  libreConnected,
  setlibreActive,
  setlibreConnected,
  hasConnection,
  profile,
  refreshProfile,
  setLibreWindow,
  patientId,
}) {
  const { t } = useTranslation()
  const { auth } = useAuth()
  const [libreURL, setLibreURL] = useState("")
  const [libreWindow, setLibreRedirectWindow] = useState()
  const [isButtonDisabled, disableButton] = useState(false)
  const [isCloseButtonDisabled, disableCloseButton] = useState(false)

  const [websocketMessage, setWebsocketMessage] = useState()
  const [socketUrl, setSocketUrl] = useState(
    BASE_URL.replace("http", "ws") +
      "/v1/patient/" +
      patientId +
      "/libre-auth-init?authToken=" +
      auth.accessToken
  )
  const { sendMessage, lastMessage } = useWebSocket(socketUrl, {
    onOpen: () => {
      console.log("Websocket open")
    },
    shouldReconnect: (closeEvent) => true,
    reconnectInterval: (closeEvent) => 3000,
    reconnectAttempts: (closeEvent) => 3,
  })

  useEffect(() => {
    if (libreURL === "") {
      disableButton(true)
    } else {
      disableButton(false)
    }
  }, [libreURL])

  useEffect(() => {
    // console.log(lastMessage)
    try {
      let data = JSON.parse(lastMessage.data)
      if (data.status === "NOT_AUTHENTICATED") {
        setWebsocketMessage("NOT_AUTHENTICATED")
      }
      if (data.status === "INVALID_TOKEN") {
        setWebsocketMessage("INVALID_TOKEN")
      }
      if (data.status === "INITIALIZED") {
        setLibreURL(data.oauthUrl)
      }
      if (data.status === "DATA_FETCH") {
        libreWindow.close()
        disableButton(true)
        disableCloseButton(true)
        setWebsocketMessage("DATA_FETCH")
        setLibreLoading(true)
      }
      if (data.status === "RUNNING") {
        setWebsocketMessage("RUNNING")
      }
      if (data.status === "FINISHED") {
        setWebsocketMessage("FINISHED")
        setLibreLoading(false)
        setlibreConnected(true)
        setlibreActive(true)
        disableCloseButton(false)
        refreshProfile()
        disableButton(false)
      }
      if (data.status === "ERROR") {
        setWebsocketMessage("ERROR")
        disableCloseButton(false)
        setlibreConnected(false)
        setLibreLoading(false)
        disableButton(false)
        removeDataSourceForProfile(auth.accessToken, profile.id, 3).then(
          async (res) => {
            if (res.status === "OK") {
              await refreshProfile()
              setLibreLoading(false)
            }
          }
        )
      }
    } catch {
      // console.log("error")
    }
  }, [lastMessage])

  async function handleConnection() {
    if (hasConnection) {
      setLibreLoading(true)
      setWebsocketMessage("REMOVING")
      removeDataSourceForProfile(auth.accessToken, profile.id, 3).then(
        async (res) => {
          if (res.status === "OK") {
            await refreshProfile()
            setLibreLoading(false)
          }
        }
      )
    } else {
      setLibreRedirectWindow(
        window.open(libreURL, "", "scrollbars=1, width=1100, height=800")
      )
    }
  }
  useEffect(() => {
    if (hasConnection) {
      setWebsocketMessage("CONNECTED")
    } else if (!hasConnection) {
      setWebsocketMessage("NOT_CONNECTED")
    }
  }, [hasConnection])

  return (
    <div>
      <OneTwoModal modalIsOpen={libreModalOpen} closeModal={setLibreModalOpen}>
        <div className="upload-container">
          <div className="upload-header-wrapper">
            <div className="upload-header">
              <div className="upload-title">{t("home.data.libre.title")}</div>
              <div className="upload-text">{t("home.data.libre.text")}</div>
            </div>
          </div>
          <div className="upload-body">
            <div className="data-modal-content">
              {hasConnection ? (
                <h3>
                  {t("home.data.libre.titleConnected", {
                    name: profile.firstName + " " + profile.lastName,
                  })}
                </h3>
              ) : (
                <h3>
                  {t("home.data.libre.titleNotConnected", {
                    name: profile.firstName + " " + profile.lastName,
                  })}
                </h3>
              )}
              <div className="data-modal-content">
                {websocketMessage === "CONNECTED" ? (
                  <p>{t("home.data.libre.innertextConnected")}</p>
                ) : websocketMessage === "NOT_CONNECTED" ? (
                  <p>{t("home.data.libre.innertext")}</p>
                ) : websocketMessage === "DATA_FETCH" ? (
                  <p>{t("home.data.libre.fetching")}</p>
                ) : websocketMessage === "RUNNING" ? (
                  <p>{t("home.data.libre.running")}</p>
                ) : websocketMessage === "FINISHED" ? (
                  <p>{t("home.data.libre.finished")}</p>
                ) : websocketMessage === "REMOVING" ? (
                  <p>{t("home.data.libre.removing")}</p>
                ) : websocketMessage === "NOT_AUTHENTICATED" ? (
                  <p>{t("home.data.libre.notAuthenticated")}</p>
                ) : websocketMessage === "INVALID_TOKEN" ? (
                  <p>{t("home.data.libre.error")}</p>
                ) : websocketMessage === "ERROR" ? (
                  <p>{t("home.data.libre.error")}</p>
                ) : null}
              </div>
            </div>
            <div className="data-modal-content flex-ju-center">
              {isLibreLoading ? <Loading /> : null}
            </div>
            <div className="data-modal-content">
              <div className="data-modal-button">
                <Button
                  onClick={
                    !isCloseButtonDisabled
                      ? () => {
                          setLibreModalOpen(false)
                        }
                      : null
                  }
                  buttonStyle={
                    !isCloseButtonDisabled
                      ? "btn--primary"
                      : "btn--primary-disabled"
                  }
                  buttonSize="btn--small"
                >
                  {t("home.data.libre.close")}
                </Button>
              </div>
              <div className="data-modal-button">
                <Button
                  onClick={
                    !isButtonDisabled
                      ? () => {
                          handleConnection()
                        }
                      : null
                  }
                  buttonStyle={
                    !isButtonDisabled ? "btn--primary" : "btn--primary-disabled"
                  }
                  buttonSize="btn--small"
                >
                  {hasConnection
                    ? t("home.data.libre.remove")
                    : t("home.data.libre.open")}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </OneTwoModal>
    </div>
  )
}
