import React, { useEffect, useState } from "react"
import useAuth from "../../../hooks/useAuth"

import uploadFile from "../../../api/uploadFile.js"

import { Loading } from "../../Loading"
import UploadFileList from "./UploadFileList"
import { useTranslation } from "react-i18next"

import "./Upload.css"
import i18next from "i18next"

export default function UploadFile({ profileId, setuploadFilePipelineId }) {
  const { auth } = useAuth()
  const { t } = useTranslation()

  const [isMounted, setMounted] = useState(false)
  const [isLoading, setloading] = useState(false)
  const [fileList, setFileList] = useState([])
  const [isProcessing, setProcessing] = useState(false)
  const [uploadResult, setUploadResult] = useState("")
  let numberOfFiles = 0
  let numberOfUploadedFiles = 0
  let errorCount = 0

  useEffect(() => {
    if (!isMounted) {
      addEventListeners()
      setMounted(true)
    }
  }, [])

  function unhighlight(event) {
    const dropAreaText = document.getElementById("drop-area-text" + profileId)
    const dropArea = document.getElementById("drop-area" + profileId)

    dropAreaText.innerHTML = t("home.data.upload.dropArea.drag")
    dropArea.classList.remove("highlight")
    dropAreaText.classList.add("onetwo-green")
  }
  function highlight(event) {
    const dropAreaText = document.getElementById("drop-area-text" + profileId)
    const dropArea = document.getElementById("drop-area" + profileId)

    dropAreaText.innerHTML = t("home.data.upload.dropArea.drop")
    dropArea.classList.add("highlight")
    dropAreaText.classList.remove("onetwo-green")
  }
  function preventDefaults(e) {
    e.preventDefault()
    e.stopPropagation()
  }

  function addEventListeners() {
    const dropArea = document.getElementById("drop-area" + profileId)
    const dropAreaText = document.getElementById("drop-area-text" + profileId)
    ;["dragenter", "dragover", "dragleave", "drop"].forEach((eventName) => {
      dropArea.addEventListener(eventName, preventDefaults)
      dropAreaText.addEventListener(eventName, preventDefaults)
    })
    ;["dragleave", "drop"].forEach((eventName) => {
      dropArea.addEventListener(eventName, unhighlight)
      dropAreaText.addEventListener(eventName, unhighlight)
    })
    ;["dragenter", "dragover"].forEach((eventName) => {
      dropArea.addEventListener(eventName, highlight)
      dropAreaText.addEventListener(eventName, highlight)
    })
    dropArea.addEventListener("drop", handleDrop)
    dropAreaText.addEventListener("drop", handleDrop)
  }

  function handlePick(event) {
    let files = event.target.files
    handleFiles(files)
  }

  function handleDrop(event) {
    let dt = event.dataTransfer
    handleFiles(dt.files)
  }

  function handleFiles(files) {
    setProcessing(true)
    setloading(true)
    let tempList = []
    for (const _file of files) {
      numberOfFiles++
      tempList.push({
        file: _file,
        status: "RECEIVED",
        id: numberOfFiles,
        message: "NULL",
        icon: "NULL",
      })
      fileHandler(_file, profileId, numberOfFiles)
    }
    setFileList(tempList)
  }

  function fileHandler(file, profileId, id) {
    setProcessing(true)
    setloading(true)
    uploadFile(file, profileId, auth.accessToken)
      .then((data) => {
        setuploadFilePipelineId(data.pipeline_item_id)
        numberOfUploadedFiles++
        setFileList(function (prev) {
          const newState = prev.map((obj) => {
            if (obj.id === id) {
              return {
                ...obj,
                status: "UPLOADED",
                message: "NULL",
                icon: "checkmark",
              }
            }
            return obj
          })
          return newState
        })
      })
      .catch((error) => {
        errorCount++
        let errorMessage = error.response.data.error.message
        setFileList(function (prev) {
          const newState = prev.map((obj) => {
            if (obj.id === id) {
              return {
                ...obj,
                status: "ERROR",
                message: errorMessage,
                icon: "alert",
              }
            }
            return obj
          })
          return newState
        })
      })
      .then(() => {
        if (errorCount + numberOfUploadedFiles === numberOfFiles) {
          setloading(false)
          if (errorCount === numberOfFiles) {
            setUploadResult(t("home.data.upload.dropArea.doneWithErrors"))
          } else if (numberOfUploadedFiles === numberOfFiles) {
            setUploadResult(t("home.data.upload.dropArea.done"))
          } else {
            setUploadResult(t("home.data.upload.dropArea.doneWithSomeErrors"))
          }
        }
      })
  }

  function resetFileDrop() {
    setProcessing(false)
    setloading(false)
    setUploadResult("")
    numberOfFiles = 0
    errorCount = 0
    numberOfUploadedFiles = 0
  }

  function clearInput(event) {
    event.target.value = ""
  }

  return (
    <div>
      <div className="data-modal-content">
        <p>{t("home.data.upload.disclaimer")}</p>
      </div>
      <div className="loading-wrapper">
        {isLoading ? (
          <Loading color="weak-white" context="loading-upload" />
        ) : (
          <div className="upload-result-text">{uploadResult}</div>
        )}
      </div>
      <div className={isProcessing ? "block" : "hidden"}>
        <UploadFileList
          fileList={fileList}
          resetFileDrop={resetFileDrop}
          isLoading={isLoading}
        />
      </div>
      <div className={isProcessing ? "hidden" : "block"}>
        <div
          className="drop-area"
          id={"drop-area" + profileId}
          onDrop={function (event) {
            handleDrop(event)
          }}
        >
          <p
            className="onetwo-green upload-help-text"
            id={"drop-area-text" + profileId}
          >
            {t("home.data.upload.dropArea.dropHere")}
          </p>
        </div>
        <div className="button-container flex-center">
          <div className="file-input">
            <label
              className="btn--primary btn btn--small small-screen-upload"
              htmlFor={"file-upload-input" + profileId}
            >
              {t("home.data.upload.button")}
            </label>
            <input
              onClick={(event) => clearInput(event)}
              onChange={function (event) {
                handlePick(event)
              }}
              type="file"
              className=""
              id={"file-upload-input" + profileId}
              hidden
              multiple
            />
            <div className="file-upload-helper">
              {t("home.data.upload.help")}
              <a
                href={"/files/OneTwoUpload-" + i18next.language + ".pdf"}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("home.data.upload.clickhere")}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
