import React, { useEffect, useState } from "react"
import { FormButton } from "../FormElements"
import { Button } from "../Button"
import { useTranslation } from "react-i18next"
import { SmallLoading } from "../Loading"

import useAuth from "../../hooks/useAuth"
import useProfiles from "../../hooks/useProfiles"

import Upload from "./datahandlers/Upload"
import Dexcom from "./datahandlers/Dexcom"
import Libre from "./datahandlers/Libre"
import Nightscout from "./datahandlers/Nightscout"
import { DataButton } from "./datahandlers/DataButton"

import getProfiles from "../../api/profiles.js"
import { getPipelineStatus } from "../../api/pipeline.js"

import {
  deleteDataForProfile,
  fetchDataForProfile,
  activateDataSourceForProfile,
} from "../../api/data.js"

import "./ProfileCardData.css"

export default function ProfileCardData({ profile, setUploadMode }) {
  const { auth } = useAuth()
  const { t } = useTranslation()
  const { profiles, setProfiles } = useProfiles()

  const [isDataLoading, setDataLoading] = useState(false)
  const [isDataErasing, setDataErasing] = useState(false)
  const [isDexcomLoading, setDexcomLoading] = useState(false)
  const [isLibreLoading, setLibreLoading] = useState(false)
  const [isNightscoutLoading, setNightscoutLoading] = useState(false)

  const [nightscoutModalOpen, setNightscoutModalOpen] = useState(false)
  const [dexcomModalOpen, setDexcomModalOpen] = useState(false)
  const [libreModalOpen, setLibreModalOpen] = useState(false)
  const [uploadModalOpen, setUploadModalOpen] = useState(false)

  const [dexcomConnected, setdexcomConnected] = useState(false)
  const [libreConnected, setlibreConnected] = useState(false)
  const [dexcomActive, setdexcomActive] = useState(false)
  const [libreActive, setlibreActive] = useState(false)
  const [dexcomWindow, setDexcomWindow] = useState(false)
  const [libreWindow, setLibreWindow] = useState(false)

  const [nightscoutConnected, setnightscoutConnected] = useState(false)
  const [nightscoutActive, setnightscoutActive] = useState(false)

  const [uploadFilePipelineId, setuploadFilePipelineId] = useState("")

  function setLoading() {
    setDataLoading(false)
    setDataErasing(false)
    setDexcomLoading(false)
    setLibreLoading(false)
    setNightscoutLoading(false)
  }
  async function refreshProfile() {
    const response = await getProfiles(auth.accessToken)
    if (JSON.stringify(response) !== JSON.stringify(profiles)) {
      setProfiles(response)
      handleDataConnection(profile)
      return "UPDATED"
    } else {
      return "NOT_UPDATED"
    }
  }

  function handleDataConnection(profile) {
    setnightscoutConnected(false)
    setnightscoutActive(false)
    setdexcomConnected(false)
    setlibreConnected(false)
    setdexcomActive(false)
    setlibreActive(false)
    if (Array.isArray(profile.dataServices) > 0) {
      console.log(profile)
      profile.dataServices.forEach((item) => {
        if (item.key === "DEXCOM") {
          setdexcomConnected(true)
          setdexcomActive(item.active)
          setLoading()
        }
        if (item.key === "TERRA") {
          setlibreConnected(true)
          setlibreActive(item.active)
          setLoading()
        }
        if (item.key === "NIGHTSCOUT") {
          setnightscoutConnected(true)
          setnightscoutActive(item.active)
          setLoading()
        }
      })
    }
  }

  async function makePrimary(source) {
    if (source === "dexcom") {
      setDexcomLoading(true)
      await activateDataSourceForProfile(auth.accessToken, profile.id, 1)
      refreshProfile()
    }
    if (source === "libre") {
      setLibreLoading(true)
      await activateDataSourceForProfile(auth.accessToken, profile.id, 3)
      refreshProfile()
    }
    if (source === "nightscout") {
      setNightscoutLoading(true)
      await activateDataSourceForProfile(auth.accessToken, profile.id, 2)
      refreshProfile()
    }
  }

  async function deleteData() {
    setDataErasing(true)
    const response = await deleteDataForProfile(auth.accessToken, profile.id)
    refreshProfile()
    return
  }

  async function pollPipeline(pipelineJobid) {
    let pipelineDone = false
    let retry = 0
    if (!pipelineDone) {
      let refreshIntervalId = setInterval(async () => {
        const response = await getPipelineStatus(
          auth.accessToken,
          pipelineJobid
        ).then((response) => {
          retry++
          if (retry === 15) {
            refreshProfile()
            setLoading(false)
            clearInterval(refreshIntervalId)
          }
          if (response.data.status === "FINISHED") {
            refreshProfile()
            setLoading(false)
            clearInterval(refreshIntervalId)
          }
        })
      }, 1000)
    }
  }

  async function fetchData() {
    setDataLoading(true)
    const response = await fetchDataForProfile(
      auth.accessToken,
      profile.id
    ).then((response) => {
      pollPipeline(response.pipeline_item_id)
      return
    })
    return
  }

  function uploadModal() {
    setDataLoading(true)
    pollPipeline(uploadFilePipelineId)
    setUploadModalOpen(false)
  }

  const close = () => {
    setUploadMode(false)
  }

  useEffect(() => {
    handleDataConnection(profile)
  }, [profile])

  return (
    <>
      <div className="data-container">
        <h3>{t("home.data.available")}</h3>
        <div className="data-container-col">
          <p>
            {profile.dataFrom == null
              ? t("home.noData")
              : t("home.data.datafrom", {
                  dataFrom: profile.dataFrom,
                  dataTo: profile.dataTo,
                })}
          </p>
          <div className="data-container-row mb-1 pb-1">
            {profile.dataFrom !== null ? (
              <Button
                onClick={() => deleteData()}
                buttonSize="btn--xsmall"
                buttonStyle="btn--delete"
              >
                {isDataErasing ? (
                  <SmallLoading />
                ) : (
                  t("home.profile.button.deleteData")
                )}
              </Button>
            ) : null}
          </div>
          <div className="data-container-row mb-1 pb-1 flex-align-center">
            {dexcomConnected || nightscoutConnected || libreConnected ? (
              <>
                <Button
                  onClick={() => fetchData()}
                  buttonSize="btn--xsmall"
                  buttonStyle="btn--form"
                >
                  {isDataLoading ? (
                    <SmallLoading />
                  ) : (
                    t("home.profile.button.fetchData")
                  )}
                </Button>
                <p> {t("home.data.fetchData")}</p>
              </>
            ) : null}
          </div>
        </div>

        <h3>{t("home.data.title")}</h3>
        <p>{t("home.data.text")}</p>
        <div className="data-container-col ">
          <DataButton
            label={
              dexcomConnected
                ? t("home.data.dexcom.connected")
                : t("home.data.dexcom.connect")
            }
            callback={() => {
              setDexcomModalOpen(true)
            }}
            isActive={dexcomActive}
            makePrimary={() => {
              makePrimary("dexcom")
            }}
            hasConnection={dexcomConnected}
            isLoading={isDexcomLoading}
          />
          <DataButton
            label={
              libreConnected
                ? t("home.data.libre.connected")
                : t("home.data.libre.connect")
            }
            callback={() => {
              setLibreModalOpen(true)
            }}
            isActive={libreActive}
            makePrimary={() => {
              makePrimary("libre")
            }}
            hasConnection={libreConnected}
            isLoading={isLibreLoading}
          />

          {/* <DataButton
            label={
              nightscoutConnected
                ? t("home.data.nightscout.connected")
                : t("home.data.nightscout.connect")
            }
            callback={() => {
              setNightscoutModalOpen(true)
            }}
            isActive={nightscoutActive}
            makePrimary={() => {
              makePrimary("nightscout")
            }}
            hasConnection={nightscoutConnected}
            isLoading={isNightscoutLoading}
          /> */}
          <DataButton
            label={t("home.data.upload.button")}
            callback={() => {
              setUploadModalOpen(true)
            }}
            isActive={false}
            upload={true}
            isLoading={false}
          />

          {/* MODALS */}
          {dexcomModalOpen && (
            <Dexcom
              dexcomModalOpen={dexcomModalOpen}
              setDexcomModalOpen={setDexcomModalOpen}
              setDexcomLoading={setDexcomLoading}
              isDexcomLoading={isDexcomLoading}
              dexcomConnected={dexcomConnected}
              setdexcomConnected={setdexcomConnected}
              setdexcomActive={setdexcomActive}
              profile={profile}
              hasConnection={dexcomConnected}
              refreshProfile={refreshProfile}
              setDexcomWindow={setDexcomWindow}
              patientId={profile.id}
            />
          )}
          {libreModalOpen && (
            <Libre
              libreModalOpen={libreModalOpen}
              setLibreModalOpen={setLibreModalOpen}
              setLibreLoading={setLibreLoading}
              isLibreLoading={isLibreLoading}
              libreConnected={libreConnected}
              setlibreConnected={setlibreConnected}
              setlibreActive={setlibreActive}
              profile={profile}
              hasConnection={libreConnected}
              refreshProfile={refreshProfile}
              setLibreWindow={setLibreWindow}
              patientId={profile.id}
            />
          )}
          <Nightscout
            nightscoutModalOpen={nightscoutModalOpen}
            setNightscoutModalOpen={setNightscoutModalOpen}
            profile={profile}
            hasConnection={nightscoutConnected}
            refreshProfile={refreshProfile}
          />
          <Upload
            uploadModalOpen={uploadModalOpen}
            setUploadModalOpen={uploadModal}
            profile={profile}
            refreshProfile={refreshProfile}
            setuploadFilePipelineId={setuploadFilePipelineId}
          />
        </div>

        <div className="card-footer">
          <FormButton label={t("home.profile.button.close")} callback={close} />
        </div>
      </div>
    </>
  )
}
