import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import i18next from "i18next"

import { assets } from "../assets/Assets"

import { OneTwoLink } from "./OneTwoLink"
import { BASE_URL } from "../api/axios"

import "./Footer.css"

export const Footer = () => {
  const { t, i18n } = useTranslation()
  let language = i18n.language
  return (
    <div className="footer">
      <div className="footer-item">
        <div className="footer-wrapper">
          <div className="footer-logo">
          <OneTwoLink width={150}link="https://www.onetwo-analytics.com/" text="OneTwo Analytics" image={assets.onetwo_analytics_logo}/>

          </div>
        </div>
      </div>

      <div className="footer-item">
        <div className="footer-wrapper">
          <div className="social-image-container">
            <a
              href={t("public.contact.facebook")}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={assets.facebook}
                height={"50px"}
                width={"50px"}
                alt="Facebook Logo"
                style={{backgroundColor: "grey", padding: "0%", margin: "0% 0", borderRadius: "30px"}}
              />
            </a>
          </div>
          <div className="social-image-container">
            <a
              href={t("public.contact.instagram")}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={assets.instagram}
                height={"50px"}
                width={"50px"}
                alt="Instagram Logo"
                style={{backgroundColor: "grey", padding: "0%", margin: "0% 0", borderRadius: "15px"}}

              />
            </a>
          </div>
        </div>
      </div>

      <div className="footer-item">
        <div className="footer-wrapper">
          <div className="footer-legal">
            <a href={"/terms/" + i18next.language}>
              {t("definition.termsType.TERMS_AND_CONDITIONS")}
            </a>
            <a href={"/privacy/" + i18next.language}>
              {t("definition.termsType.PRIVACY_POLICY")}
            </a>
            <a
              href={"/files/OneTwoManual-" + i18next.language + ".pdf"}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("public.user_manual")}
            </a>
          </div>
        </div>
      </div>

      <div className="footer-item">
        <div className="footer-wrapper">
          <img
            src={assets.ce}
            height="50px"
            alt="CE Mark logo"
            style={{backgroundColor: "white", padding: "5%", margin: "2% 0"}}
          />
        </div>
      </div>

      <div className="footer-item">
        <div className="footer-wrapper">
          <div className="footer-team">
            <p>
              <b>{t("public.contact.developer")}</b>
            </p>
            <p>{t("public.contact.company")}</p>
            <p>{t("public.contact.orgnumber")}</p>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Footer
