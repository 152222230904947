import React, { useState } from "react"

import OneTwoModal from "../OneTwoModal"
import { Button } from "../../Button"
import { FormButton } from "../../FormElements"
import UploadFile from "./UploadFile"
import { useTranslation } from "react-i18next"

// import "./Upload.css"

export default function Upload({
  uploadModalOpen,
  setUploadModalOpen,
  profile,
  refreshProfile,
  setuploadFilePipelineId,
}) {
  const { t } = useTranslation()

  return (
    <div>
      <OneTwoModal
        modalIsOpen={uploadModalOpen}
        closeModal={setUploadModalOpen}
      >
        <div className="upload-container">
          <div className="upload-header-wrapper">
            <div className="upload-header">
              <div className="upload-title">{t("home.data.upload.title")}</div>
              <div className="upload-text">{t("home.data.upload.text")}</div>
            </div>
          </div>
          <div className="upload-body">
            <div className="data-modal-content">
              <h3>
                {t("home.data.upload.titleConnected", {
                  name: profile.firstName + " " + profile.lastName,
                })}
              </h3>
            </div>
            <UploadFile
              profileId={profile.id}
              setuploadFilePipelineId={setuploadFilePipelineId}
            />
          </div>
          <div className="data-modal-button">
            <Button
              onClick={() => {
                refreshProfile()
                setUploadModalOpen(false)
              }}
              buttonStyle="btn--primary"
              buttonSize="btn--small"
            >
              {t("home.data.upload.close")}
            </Button>
          </div>
        </div>
      </OneTwoModal>
    </div>
  )
}
