import React, { useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"

import useAuth from "../hooks/useAuth"
import useProfiles from "../hooks/useProfiles"
import getProfiles from "../api/profiles"

import { useTranslation } from "react-i18next"

import Navbar from "../component/Navbar"
import { Button } from "../component/Button"

import "./Page.css"

function Dexcom() {
  // const { auth } = useAuth()
  const { t } = useTranslation()
  // const { profiles, setProfiles } = useProfiles()

  const [searchParams] = useSearchParams()
  let profileId = searchParams.get("profileId")
  let status = searchParams.get("status")

  // async function refreshProfile() {
  //   const response = await getProfiles(auth.accessToken)
  //   setProfiles(response)
  // }

  // useEffect(() => {
  //   if (status === "ok") {
  //     refreshProfile()
  //   }
  // })
  return (
    <>
      <Navbar />
      <section id="dexcom" className="dexcom">
        <h1 className="mb-3">
          {status === "ok" ? t("dexcom.success") : t("dexcom.notsuccess")}
        </h1>
        <Button
          buttonStyle="btn--primary"
          buttonSize="btn--large"
          onClick={() => {
            window.close()
          }}
        >
          {t("dexcom.close")}
        </Button>
      </section>
    </>
  )
}

export default Dexcom
