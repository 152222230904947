import React, { useEffect, useState } from "react"
import { Button } from "../Button"
import { useTranslation } from "react-i18next"

const ProfileCardHeader = ({
  profileId,
  title,
  subtitle,
  isActive,
  setActive,
}) => {
  const { t } = useTranslation()

  function toggle() {
    setActive(isActive ? false : true)
  }

  return (
    <div className="card-header" onClick={toggle}>
      <i className="card-header-icon onetwo-icon">
        {profileId ? t("icons.person") : t("icons.personAdd")}
      </i>
      <div className="card-header-description">
        <div className="card-header-title">{title}</div>
        <div className="card-header-subtitle">{subtitle}</div>
      </div>
      <div
        className={isActive ? "card-header-arrow open" : "card-header-arrow"}
      >
        <Button buttonStyle="none">
          <i className="onetwo-icon icon-medium">{t("icons.arrowLeft")}</i>
        </Button>
      </div>
    </div>
  )
}

export default ProfileCardHeader
