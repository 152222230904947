import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { Button } from "./Button"

const NAME_REGEX = /^[^0-9!\\|/"'&%#()?*@><:;^¨+=€$§°,_]{2,}$/
const NATIONAL_ID_REGEX = /^[\d]{8}-?[\d]{4}$/
const PASSWORD_REGEX = /(?=.*\d)(?=.*[a-zåöä])(?=.*[A-ZÅÄÖ]){8,}/
const THIS_YEAR = new Date().getFullYear()

export const isValidName = (name) => {
  return NAME_REGEX.test(name || "")
}

export const isValidNationalId = (nationalId) => {
  if (nationalId == null) return true
  return NATIONAL_ID_REGEX.test(nationalId || "")
}

export const isValidYear = (year) => {
  return THIS_YEAR - 120 < year && year <= THIS_YEAR
}

export const isValidPassword = (password) => {
  return PASSWORD_REGEX.test(password || "")
}

export const FormInput = ({
  id,
  label,
  value,
  disabled = false,
  autoComplete = "off",
  valid = null,
  reference,
  type,
  setValue,
  setFocus = () => {},
}) => {
  const [valueChanged, setValueChanged] = useState(false)
  const [classNames, setClassNames] = useState("form-control")

  useEffect(() => {
    if (valid == null) setClassNames("form-control empty-input")
    else if (!valid && value != null)
      setClassNames("form-control failed-validation")
    else if (!valid && value == null) setClassNames("form-control empty-input")
    else if (!valueChanged) setClassNames("form-control empty-input")
    else setClassNames("form-control")
  }, [valid, valueChanged])

  useEffect(() => {}, [classNames])

  return (
    <div className="form-group">
      <label htmlFor={id} className="form-label">
        {label}
      </label>
      <input
        id={id}
        disabled={disabled}
        className={classNames}
        ref={reference}
        value={value || ""}
        type={type}
        autoComplete={autoComplete}
        onChange={(e) => {
          setValueChanged(true)
          setValue(e.target.value)
        }}
        aria-invalid={valid ? "false" : "true"}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
      ></input>
    </div>
  )
}

export const FormSelect = ({
  id,
  label,
  value,
  fallbackValue,
  disabled,
  valid,
  reference,
  setValue,
  setFocus,
  options,
}) => {
  const { t } = useTranslation()

  const [valueChanged, setValueChanged] = useState(false)
  const [classNames, setClassNames] = useState("form-control")

  useEffect(() => {
    if (value == null || !valueChanged)
      setClassNames("form-control empty-input")
    else if (!valid) setClassNames("form-control failed-validation")
    else setClassNames("form-control")
  }, [valid, valueChanged])

  return (
    <div className="form-group">
      <label htmlFor={id} className="form-label">
        {label}
      </label>
      <select
        id={id}
        disabled={disabled}
        className={classNames}
        ref={reference}
        value={value || fallbackValue}
        onChange={(e) => {
          setValueChanged(true)
          setValue(e.target.value)
        }}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
        aria-invalid={valid ? "false" : "true"}
      >
        {options?.map((option) => {
          return (
            <option key={option} value={option}>
              {t("definition." + id + "." + option)}
            </option>
          )
        })}
      </select>
    </div>
  )
}

export const FormCheckBoxes = ({
  id,
  label,
  values,
  disabled,
  setValue,
  optionList,
}) => {
  const { t } = useTranslation()

  return (
    <div className="form-group">
      <label htmlFor={id} className="form-label">
        {label}
      </label>
      <div className={`form-checkbox-container ${disabled ? "disabled" : ""}`}>
        {optionList?.map((option) => {
          return (
            <div className="form-checkbox-group" key={option}>
              <input
                id={option}
                key={option}
                disabled={disabled}
                type="checkbox"
                className="form-checkbox-group"
                checked={values.indexOf(option) < 0 ? false : true}
                onChange={(e) => setValue(option, e.target.checked)}
                aria-invalid="false"
              ></input>
              <label htmlFor={option}>
                {t("definition." + id + "." + option)}
              </label>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export const FormButton = ({
  label,
  callback,
  buttonClass = "btn--form",
  buttonSize = "btn--small",
}) => {
  return (
    <Button
      buttonSize={buttonSize}
      buttonStyle={buttonClass}
      onClick={callback}
    >
      {label}
    </Button>
  )
}

export const FormCheckBox = ({ label, checked, callback }) => {
  const { t } = useTranslation()
  return (
    <div className="form-checkbox">
      <i
        onClick={() => {
          callback(checked ? false : true)
        }}
        className={`form-checkbox-icon ${checked ? "checked" : ""}`}
      >
        {t("icons.checkmark")}
      </i>
      <p className="form-checkbox-label">{label}</p>
    </div>
  )
}
