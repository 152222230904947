import React, { useRef, useState, useEffect } from "react"
import { useNavigate, useLocation, useSearchParams } from "react-router-dom"
import { useTranslation } from "react-i18next"

import useLogin from "../hooks/useLogin"
import { dbRequestPasswordReset, dbPasswordReset } from "../api/passwords"

import { Button } from "./Button"
import { Loading } from "./Loading"

import { FormInput, FormButton, isValidPassword } from "./FormElements"

import "./SignInForm.css"

function SignInForm() {
  /* The sign in form can be in any of the following states:

  SIGN_IN
  SIGN_IN_WAITING
  SIGN_IN_ERROR
  
  REQUEST_PASSWORD_RESET
  REQUEST_PASSWORD_RESET_WAITING
  REQUEST_PASSWORD_RESET_ERROR
  REQUEST_PASSWORD_RESET_DONE
  
  PASSWORD_RESET
  PASSWORD_RESET_WAITING
  PASSWORD_RESET_ERROR
  PASSWORD_RESET_DONE
  */

  const { t } = useTranslation()

  const navigate = useNavigate()
  const location = useLocation()
  const from = location.state?.from?.pathname || "/home"

  const [searchParams, setSearchParams] = useSearchParams()

  const emailRef = useRef()
  const passwordRef = useRef()

  // If query parameter oob exists, initial state is password reset
  const [formState, setFormState] = useState(
    searchParams.get("oob") ? "PASSWORD_RESET" : "SIGN_IN"
  )
  const [isLoading, setLoading] = useState(false)

  const [headline, setHeadline] = useState()
  const [description, setDescription] = useState()
  const [errorDescription, setErrorDescription] = useState("")

  const [email, setEmail] = useState()
  const [password, setPassword] = useState()

  const login = useLogin()

  useEffect(() => {
    if (searchParams.get("oob")) setFormState("PASSWORD_RESET")
  }, [searchParams])

  useEffect(() => {
    setErrorDescription("")
  }, [email, password])

  useEffect(() => {
    /* Update widget headline and description on state change */
    setHeadline(t("signIn.headline." + formState))
    setDescription(
      formState.includes("ERROR")
        ? errorDescription
        : t("signIn.description." + formState)
    )
  }, [formState])

  const handleSubmit = async (e) => {
    e.preventDefault()
    setFormState("SIGN_IN_WAITING")
    setLoading(true)
    try {
      await login(email, password)
      setEmail("")
      setPassword("")
      setFormState("SIGN_IN")
      navigate(from, { replace: true })
    } catch (err) {
      setLoading(false)
      setFormState("SIGN_IN_ERROR")
      if (!err?.response) {
        setLoading(false)
        setErrorDescription(t("signIn.submit.error.NO_RESPONSE"))
      } else if ("error" in err.response.data) {
        setLoading(false)
        console.log(err.response.data.error.message)
        setErrorDescription(
          t("signIn.submit.error." + err.response.data.error.message)
        )
      } else {
        setLoading(false)
        setErrorDescription(t("signIn.submit.error.SIGN_IN_ERROR"))
      }
    }
    setLoading(false)
  }

  const handleRequestPasswordReset = async (e) => {
    e.preventDefault()
    setFormState("REQUEST_PASSWORD_RESET_WAITING")
    setLoading(true)
    try {
      await dbRequestPasswordReset(email)
      setFormState("REQUEST_PASSWORD_RESET_DONE")
    } catch (err) {
      setFormState("REQUEST_PASSWORD_RESET_ERROR")
      if (!err?.response) {
        setErrorDescription(t("signIn.submit.error.NO_RESPONSE"))
      } else if ("error" in err.response.data) {
        setErrorDescription(
          t("signIn.submit.error." + err.response.data.error.message)
        )
      } else {
        setErrorDescription(
          t("signIn.submit.error.REQUEST_PASSWORD_RESET_ERROR")
        )
      }
    }
    setLoading(false)
  }

  const handlePasswordReset = async (e) => {
    e.preventDefault()
    if (!isValidPassword(password)) {
      setFormState("PASSWORD_RESET_ERROR")
      setErrorDescription(t("signIn.submit.error.INVALID_PASSWORD"))
      return
    }
    setFormState("PASSWORD_RESET_WAITING")
    setLoading(true)
    try {
      await dbPasswordReset(searchParams.get("oob"), password)
      setSearchParams()
      setPassword("")
      setFormState("SIGN_IN")
    } catch (err) {
      setFormState("PASSWORD_RESET_ERROR")
      if (!err?.response) {
        setErrorDescription(t("signIn.submit.error.NO_RESPONSE"))
      } else if ("error" in err.response.data) {
        setErrorDescription(
          t("signIn.submit.error." + err.response.data.error.message)
        )
      } else {
        setErrorDescription(t("signIn.submit.error.PASSWORD_RESET_ERROR"))
      }
    }
    setLoading(false)
  }

  const ResetRequestComplete = () => {
    return (
      <div className="widget-done">
        <p className="form-group">{description}</p>
        <Button
          buttonStyle="btn--form"
          buttonSize="btn--small"
          onClick={() => {
            setFormState("SIGN_IN")
          }}
        >
          {t("signIn.button.close")}
        </Button>
      </div>
    )
  }

  const Waiting = () => {
    return (
      <div className="widget-waiting">
        <p className="form-group">{description}</p>
      </div>
    )
  }

  const Error = () => {
    return (
      <div className="widget-error">
        <p className="form-group">{description}</p>
        <FormButton
          label={t("signIn.button.close")}
          callback={() => {
            if (formState === "PASSWORD_RESET_ERROR") {
              setLoading(false)
              setFormState("PASSWORD_RESET")
            } else {
              setLoading(false)
              setFormState("SIGN_IN")
            }
          }}
        />
      </div>
    )
  }

  const SignInForm = (
    <form onSubmit={handleSubmit}>
      <p className="form-group">{description}</p>
      <FormInput
        id="email"
        label={t("signIn.label.email")}
        value={email}
        reference={emailRef}
        autoComplete="on"
        type="email"
        setValue={setEmail}
      />
      <FormInput
        id="password"
        label={t("signIn.label.password")}
        value={password}
        reference={passwordRef}
        autoComplete="on"
        type="password"
        setValue={setPassword}
      />
      <div className="form-button-row right forgot-password">
        <a
          onClick={() => {
            setFormState("REQUEST_PASSWORD_RESET")
          }}
        >
          {t("signIn.forgotPassword")}
        </a>
      </div>
      <div className="form-button-row center">
        <FormButton label={t("signIn.button.submit")} />
      </div>
    </form>
  )

  const RequestPasswordReset = (
    <form onSubmit={handleRequestPasswordReset}>
      <p className="form-group">{description}</p>
      <FormInput
        id="email"
        label={t("signIn.label.email")}
        value={email}
        reference={emailRef}
        type="email"
        setValue={setEmail}
      />
      <div className="form-button-row center mt-2">
        <FormButton
          label={t("signIn.button.close")}
          callback={() => {
            setFormState("SIGN_IN")
          }}
        />
        <FormButton label={t("signIn.button.reset")} />
      </div>
    </form>
  )

  const PasswordReset = (
    <form onSubmit={handlePasswordReset}>
      <p className="form-group">{description}</p>
      <FormInput
        id="password"
        label={t("signIn.label.password")}
        value={password}
        valid={isValidPassword(password)}
        reference={passwordRef}
        type="password"
        setValue={setPassword}
      />

      <div className="form-button-row center mt-2">
        <FormButton
          label={t("signIn.button.close")}
          callback={() => {
            setSearchParams()
            setPassword()
            setFormState("SIGN_IN")
          }}
        />
        <FormButton label={t("signIn.button.setPassword")} />
      </div>
    </form>
  )

  return (
    <div className="sign-in-card">
      <h2>{headline}</h2>
      <div className="sign-in-container">
        <div className={isLoading ? "not-hidden" : "hidden"}>
          <Loading color="weak-white" context="login" />
        </div>
        {formState === "SIGN_IN" ? (
          SignInForm
        ) : formState.includes("WAIT") ? (
          <Waiting />
        ) : formState === "REQUEST_PASSWORD_RESET" ? (
          RequestPasswordReset
        ) : formState === "REQUEST_PASSWORD_RESET_DONE" ? (
          <ResetRequestComplete />
        ) : formState === "PASSWORD_RESET" ? (
          PasswordReset
        ) : (
          <Error />
        )}
      </div>
    </div>
  )
}

export default SignInForm
