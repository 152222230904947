import React from "react"
import Navbar from "../component/Navbar"
import Footer from "../component/Footer"
import { Terms } from "../component/settings/Terms"
import { UserData } from "../component/settings/UserData"
import { ChangePassword } from "../component/settings/ChangePassword"
import DeleteAccount from "../component/settings/DeleteAccount"

function Settings() {
  return (
    <section id="settings" className="settings">
      <Navbar />
      <div className="settings-container">
        <UserData />
        <ChangePassword />
        <Terms />
        <DeleteAccount />
      </div>
      <Footer />
    </section>
  )
}

export default Settings
