import React, { useState, useEffect } from "react"
import { useLocation, useNavigate, Link } from "react-router-dom"
import { useTranslation } from "react-i18next"

import { faTimes, faBars } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import "./Navbar.css"
import { Button } from "./Button"

import useAuth from "../hooks/useAuth"
import useLogout from "../hooks/useLogout"
import useProfiles from "../hooks/useProfiles"
import Dropdown from "./LanguageDropdown"

import { assets } from "../assets/Assets"

function Navbar() {
  const { t } = useTranslation()
  const [click, setClick] = useState(false)
  const [buttonVisible, setButton] = useState(true)

  const handleClick = () => setClick(!click)
  const closeMobileMenu = () => setClick(false)

  const { profiles } = useProfiles()
  const { auth } = useAuth()
  const navigate = useNavigate()
  const location = useLocation()
  const activeLink = location.pathname + location.hash
  const loggedIn = auth.user ? true : false

  const logout = useLogout()

  const signOut = async () => {
    await logout()
    navigate("/")
  }

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false)
    } else {
      setButton(true)
    }
  }

  // When reloading page check if button should be displayed.
  useEffect(() => {
    // console.log("Logged in: " + loggedIn)
    // console.log("Profiles loaded: " + profiles)
    showButton()
  }, [])

  window.addEventListener("resize", showButton)

  return (
    <nav className="navbar">
      <div className="navbar-container">
        <Link to="/" className="navbar-logo" onClick={closeMobileMenu}>
          <img
            className="navbar-logo-image"
            src={assets.onetwoLogo}
            alt="One Two Logo"
          />
        </Link>

        <div className="menu-icon" onClick={handleClick}>
          <FontAwesomeIcon
            icon={click ? faTimes : faBars}
            className="nav-menu-icon"
          />
        </div>

        <ul className={click ? "nav-menu active" : "nav-menu"}>
          {/* <li className={!loggedIn ? "nav-item" : "nav-item hidden"}>
            <a
              className={
                activeLink === "/#home" ? "nav-links active" : "nav-links"
              }
              href="/#home"
              onClick={closeMobileMenu}
            >
              {t("navBar.home")}
            </a>
          </li> */}

          {/* <li className={!loggedIn ? "nav-item" : "nav-item hidden"}>
            <a
              className={
                activeLink === "/#download" ? "nav-links active" : "nav-links"
              }
              href="/#download"
              onClick={closeMobileMenu}
            >
              {t("navBar.download")}
            </a>
          </li> */}

          {/* <li className={!loggedIn ? "nav-item" : "nav-item hidden"}>
            <a
              className={
                activeLink === "/#features" ? "nav-links active" : "nav-links"
              }
              href="/#features"
              onClick={closeMobileMenu}
            >
              {t("navBar.features")}
            </a>
          </li> */}

          {/* <li className={!loggedIn ? "nav-item" : "nav-item hidden"}>
            <a
              className={
                activeLink === "/#contact" ? "nav-links active" : "nav-links"
              }
              href="/#contact"
              onClick={closeMobileMenu}
            >
              {t("navBar.contact")}
            </a>
          </li> */}
          <li className={loggedIn ? "nav-item" : "nav-item hidden"}>
            <Link
              to="/home"
              className={
                activeLink === "/home" ? "nav-links active" : "nav-links"
              }
              onClick={closeMobileMenu}
            >
              {profiles?.length > 1
                ? t("navBar.profiles")
                : t("navBar.profile")}
            </Link>
          </li>

          <li className={loggedIn ? "nav-item" : "nav-item hidden"}>
            <Link
              to="/settings"
              className={
                activeLink === "/settings" ? "nav-links active" : "nav-links"
              }
              onClick={closeMobileMenu}
            >
              {t("navBar.settings")}
            </Link>
          </li>
          {loggedIn && !buttonVisible ? (
            <li className="nav-item nav-item--button">
              <Button
                buttonStyle="btn--primary"
                buttonSize="btn--small"
                onClick={signOut}
              >
                {t("navBar.signOut")}
              </Button>{" "}
            </li>
          ) : (
            <></>
          )}
          {/* {!loggedIn && !buttonVisible ? (
            null
            // <li className="nav-item nav-item--button">
            //   <Button
            //     buttonStyle="btn--primary"
            //     buttonSize="btn--small"
            //     linkTo="/sign-in"
            //   >
            //     {t("navBar.signIn")}
            //   </Button>{" "}
            // </li>
          ) : (
            <></>
          )} */}
          {buttonVisible ? null : (
            <li className="nav-item nav-item--button">
              <Dropdown />
            </li>
          )}
        </ul>
        {/* {!loggedIn && buttonVisible && (
          <Button
            buttonStyle="btn--primary"
            buttonSize="btn--small"
            linkTo="/sign-in"
          >
            {t("navBar.signIn")}
          </Button>
        )} */}
        {loggedIn && buttonVisible && (
          <Button
            onClick={signOut}
            buttonStyle="btn--primary"
            buttonSize="btn--small"
            linkTo="/sign-in"
          >
            {t("navBar.signOut")}
          </Button>
        )}
        {buttonVisible ? <Dropdown /> : null}
      </div>
    </nav>
  )
}

export default Navbar
