import axios from "../api/axios"
import getProfiles from "../api/profiles"
import useAuth from "./useAuth"
import useProfiles from "./useProfiles"

const useRefreshToken = () => {
  const { setAuth } = useAuth()
  const { setProfiles } = useProfiles()

  const refresh = async () => {
    const response = await axios.get("/v1/auth/refresh", {
      withCredentials: true,
    })
    setAuth((prev) => {
      let roles = ["USER"]
      if (response.data.user.isSuperUser) {
        roles.push("ADMIN")
      }

      return {
        ...prev,
        user: response.data.user,
        roles: roles,
        accessToken: response.data.auth.token,
      }
    })
    const profiles = await getProfiles(response?.data?.auth.token)
    setProfiles(profiles)
    return response.data.auth.token
  }

  return refresh
}

export default useRefreshToken
