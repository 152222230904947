import React, { useState } from "react"
import useAuth from "../../../hooks/useAuth"
import OneTwoModal from "../OneTwoModal"
import { FormButton, FormInput } from "../../FormElements"
import { Button } from "../../Button"
import axios from "../../../api/axios"
import { useTranslation } from "react-i18next"
import { useEffect } from "react"
import {
  addNightscoutForProfile,
  removeDataSourceForProfile,
} from "../../../api/data.js"

export default function Nightscout({
  nightscoutModalOpen,
  setNightscoutModalOpen,
  hasConnection,
  profile,
  refreshProfile,
}) {
  const { auth } = useAuth()
  const { t } = useTranslation()
  const [nightscoutURL, setNightscoutURL] = useState("https://")
  const [validNightscoutURL, setValidNightscoutURL] = useState()
  const [nightscoutToken, setNightscoutToken] = useState("")
  const [validNightscoutToken, setValidNightscoutToken] = useState()

  async function deleteNightscout() {
    await removeDataSourceForProfile(auth.accessToken, profile.id, 2)
    setNightscoutURL("")
    setNightscoutToken("")
    setValidNightscoutURL(false)
    refreshProfile()
  }

  const NightscoutLink = () => {
    return (
      <div className="nightscout-link-wrapper">
        <a href={nightscoutURL} target="_new">
          {nightscoutURL}
        </a>
        <Button
          buttonSize="btn--small"
          buttonStyle="btn--primary"
          onClick={() => {
            deleteNightscout()
          }}
        >
          {t("home.nightscout.remove")}
        </Button>
      </div>
    )
  }
  const checkNightscout = () => {
    setValidNightscoutURL(false)
    axios
      .get(nightscoutURL + "/api/v1/status?token=" + nightscoutToken)
      .then((res) => {
        if (res.status === 200) {
          setValidNightscoutURL(true)
        } else {
          setValidNightscoutURL(false)
        }
      })
  }
  useEffect(() => {}, [validNightscoutURL])

  useEffect(() => {
    if (Array.isArray(profile.dataServices) > 0) {
      var nightscout = profile.dataServices.find(function (item) {
        return item.key === "NIGHTSCOUT"
      })
      if (typeof nightscout !== "undefined") {
        setNightscoutURL(nightscout.url)
        setNightscoutToken(nightscout.accessToken)
      }
    }
  }, [profile])

  async function save() {
    if (validNightscoutURL) {
      await addNightscoutForProfile(
        auth.accessToken,
        profile.id,
        nightscoutURL,
        nightscoutToken
      )
      refreshProfile()
      setNightscoutModalOpen(false)
    } else {
      console.log("cant save nightscout")
    }
  }

  return (
    <div>
      <OneTwoModal
        modalIsOpen={nightscoutModalOpen}
        closeModal={setNightscoutModalOpen}
      >
        <div className="upload-container">
          <div className="upload-header-wrapper">
            <div className="upload-header">
              <div className="upload-title">
                {t("home.data.nightscout.title")}
              </div>
              <div className="upload-text">
                {t("home.data.nightscout.text")}
              </div>
            </div>
          </div>
          <div className="upload-body">
            <div className="data-modal-content">
              {hasConnection ? (
                <h3>
                  {t("home.data.nightscout.titleConnected", {
                    name: profile.firstName + " " + profile.lastName,
                  })}
                </h3>
              ) : (
                <h3>
                  {t("home.data.nightscout.titleNotConnected", {
                    name: profile.firstName + " " + profile.lastName,
                  })}
                </h3>
              )}
            </div>
            <div className="data-modal-content">
              <FormInput
                id="ns"
                label={t("home.data.nightscout.address")}
                value={nightscoutURL}
                valid={validNightscoutURL}
                setValue={setNightscoutURL}
                buttonSize="btn--xsmall"
              />
              <FormButton
                label={t("home.data.nightscout.test")}
                callback={checkNightscout}
              />
            </div>
            <div className="data-modal-content">
              <FormInput
                id="nsApiKey"
                label={t("home.data.nightscout.apikey")}
                value={nightscoutToken}
                valid={validNightscoutToken}
                setValue={setNightscoutToken}
                buttonSize="btn--xsmall"
              />
            </div>
            <div className="data-modal-content">
              {validNightscoutURL || hasConnection ? <NightscoutLink /> : null}
            </div>
            <div className="data-modal-content">
              <div className="data-modal-button">
                <Button
                  onClick={() => {
                    refreshProfile()
                    setNightscoutModalOpen(false)
                  }}
                  buttonStyle="btn--primary"
                  buttonSize="btn--small"
                >
                  {t("home.data.nightscout.close")}
                </Button>
              </div>
              <div className="data-modal-button">
                <Button
                  buttonStyle={
                    validNightscoutURL
                      ? "btn--primary"
                      : "btn--primary-disabled"
                  }
                  buttonSize="btn--small"
                  onClick={save}
                >
                  {t("home.data.nightscout.save")}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </OneTwoModal>
    </div>
  )
}
