import React from "react"
import Navbar from "../component/Navbar"
import SignInForm from "../component/SignInForm"
import {assets} from "../assets/Assets"
import {OneTwoLink} from "../component/OneTwoLink"
import "./Page.css"



function SignIn() {
  return (
    <>
      <Navbar />
      <section id="signin" className="signin">
        <div className="signin-container">
          <div className="signin-container-form">
            <SignInForm />
          </div>
          <div className="link-container">
            <OneTwoLink link="https://www.onetwo-analytics.com/" text="OneTwo Analytics" image={assets.onetwo_analytics_logo}/>
          </div>
        </div>
      </section>
    </>
  )
}

export default SignIn
