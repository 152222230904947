import axios from "./axios"

const getProfiles = async (token) => {
  try {
    const response = await axios.get("/v1/patients", {
      //   signal: controller.signal,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
    // return []
    return response.data
  } catch (err) {
    throw err
  }
}

export default getProfiles

export const dbPostProfile = async (profileData, token) => {
  try {
    const response = await axios.post("/v1/patient", profileData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
    return response.data
  } catch (err) {
    throw err.response.data.error.message
  }
}

export const dbPutProfile = async (profileId, profileData, token) => {
  try {
    const response = await axios.put("/v1/patient/" + profileId, profileData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
    return response.data
  } catch (err) {
    throw err.response.data.error.message
  }
}

export const dbDeleteProfile = async (id, token) => {
  try {
    const response = await axios.delete("/v1/patient/" + id, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
    return response.data
  } catch (err) {
    throw err.response.data.error.message
  }
}
