import React from "react"
import { useTranslation } from "react-i18next"
import useAuth from "../../hooks/useAuth"
import { Title } from "../../component/Title"

import "./Settings.css"

const definition = { 1: "terms", 2: "privacy_policy" }
const BASE_URL = process.env.REACT_APP_DDA_API_HOST

export function Terms() {
  const { t, i18n } = useTranslation()
  const { auth } = useAuth()

  const approved = []

  async function getDocs(index) {
    let documentType = definition[approved[index].typeId]
    let version = approved[index].versionApproved
    let languageCode = "sv"

    window.open(
      BASE_URL +
        "/v1/onetwo/legal?documentType=" +
        documentType +
        "&version=" +
        version +
        "&languageCode=" +
        languageCode
    )
  }

  let sortedLegal = auth.user.legalApprovals.sort(function (a, b) {
    var x = a["versionApproved"]
    var y = b["versionApproved"]
    return x < y ? -1 : x > y ? 1 : 0
  })
  sortedLegal.reverse()
  approved.push(sortedLegal[0])
  approved.push(sortedLegal[1])

  return (
    <section className="settings-terms">
      <Title title={t("settings.terms.title")} />
      <p className="settings-paragraph">{t("settings.terms.paragraph")}</p>
      <div className="terms">
        {approved.map(function (item, index) {
          return (
            <div
              className="terms-item"
              key={index}
              onClick={() => getDocs(index)}
            >
              <div className="terms-item-col-1">
                <div className="terms-document-link">
                  <i className="onetwo-icon terms-item-icon">
                    {t("icons.document")}
                  </i>
                </div>
              </div>

              <div className="terms-item-col-2">
                <div className="terms-item-link">
                  <p className="terms-type">
                    {t("definition.termsType." + item.type)}
                  </p>
                  <div>
                    <p>
                      {t("settings.terms.dateApproved") +
                        ": " +
                        new Date(item.approvedAt).toLocaleString(i18n.language)}
                    </p>
                    <p>
                      {t("settings.terms.version") +
                        ": " +
                        item.versionApproved}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </section>
  )
}
