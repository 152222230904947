export const privacy_policy_sv = `14 december, 2022
OneTwo Analytics AB

# Integritetspolicy

Version: 1.1.5

## 1. INTRODUKTION
OneTwo Diabetes ges ut av bolaget OneTwo Analytics AB (”OneTwo Analytics”, ”vi”, ”oss”, etc). Det är viktigt att de personer som använder våra tjänster, produkter, besöker vår webbplats etc. (tillsammans kallade ”Produkter”) känner sig säkra och informeras om hur vi hanterar användarnas personuppgifter (”användardata”). Vi strävar efter att upprätthålla högsta möjliga standard för skydd av personuppgifter. Denna integritetspolicy ("Integritetspolicy") beskriver hur vi samlar in, använder och hanterar information och personligt identifierbar information om våra användare.

OneTwo Analytics arbetar för att förbättra livet för personer med diabetes och stöder vårdgivare som tillhandahåller vård för dessa personer. Vi är glada att se att du är intresserad av våra produkter.

## 2. ANSVAR FÖR BEHANDLING AV PERSONUPPGIFTER
OneTwo Analytics är ett svenskt företag som utvecklar och driver en uppsättning beslutsstödverktyg för personer med diabetes och kliniker som tillhandahåller vård för dessa patienter. OneTwo Analytics är personuppgiftsansvarig för behandling av personuppgifter som användare registrerar när de använder våra produkter.

### Kontakter
Om du har några frågor eller kommentarer angående vår behandling av dina personuppgifter i samband med din användning av produkterna är du alltid välkommen att kontakta oss på [www.onetwo-diabetes.com](http://www.onetwo-diabetes.com) eller genom att skicka ett e-postmeddelande till oss på [support@onetwo-diabetes.com](mailto:support@onetwo-diabetes.com).

Contact information:

OneTwo Analytics AB

Kungsgatan 84

112 27 Stockholm

## 3. INSAMLING AV PERSONUPPGIFTER
### 3.1 Personuppgifter som registreras via din användning av våra produkter
OneTwo Analytics behandlar personuppgifter om dig som du registrerar via ditt konto. Dessa uppgifter inkluderar namn och e-postadress när du öppnar ett användarkonto hos oss och all information som du registrerar när du använder våra produkter. Dessutom kan vi automatiskt samla in och behandla följande information: (I) Teknisk information, inklusive IP-adress, inloggningsinformation, språkinställningar etc.; och (II) Information om din användning av appen, inklusive vilka funktioner du har använt och när, etc.

Information om vår användning av cookies finns i OneTwo Analytics cookiepolicy

## 4. VAR DINA PERSONUPPGIFTER LAGRAS
All personlig information lagras och hanteras främst inom EU / EES. Om vi skulle besluta att lagra data utanför EU / EES skulle vi alltid vidta nödvändiga åtgärder i enlighet med gällande sekretessregler för att säkerställa att dina uppgifter lagras säkert.

## 5 VARFÖR PERSONLIGA DATA BEHANDLAS
### 5.1 Allmänna ändamål
(i) att behandla din ansökan eller avsluta ditt konto,
(ii) för att identifiera din identitet och ålder,
(iii) för att upprätthålla korrekt och uppdaterad information om dig,
(iv) för att du ska kunna använda våra produkter,
(v) för att analysera din användning av våra produkter och förbättra produkterna,
(vi) att administrera betalningar,
(vii) för att annars kunna tillhandahålla produkterna till dig och erbjuda support.

### 5.2 Nödvändig bearbetning
Om du ger ditt samtycke bearbetar vi de användardata som anges nedan för att kunna tillhandahålla våra produkter. Om du inte samtycker till den nödvändiga behandlingen kan du inte använda OneTwo Analytics produkter. Du kan ge dina samtycken under registreringsprocessen och hantera dem i kontoinställningarna. (Se avsnitt 10 om återkallande av samtycke).

### 5.2.1 Nödvändiga användardata
För att skydda dina användardata kan våra produkter endast användas i samband med ett användarkonto. För att skapa ett användarkonto och för att använda våra produkter kräver och bearbetar vi följande användardata:

 - Förnamn
 - Efternamn
 - Personnummer
 - Mobilnummer
 - E-postadress
 - Lösenord
 - Registrerings datum
 - Kontinuerlig glukosmonitor som du använder
 - Samtycksstatus
 - Enhets-ID, tillverkare, enhetstyp, operativsystemversion
 - Språk, land, tidszon
 - IP-adress

Vi samlar också in medicinska data: blodsockermätning och sensordata som sensorvärde, tid och tidszon, födelsedatum, kön, diabetes typ, debutår och grundläggande diabetesmedicinering.

### 5.2.2 Nödvändiga syften
Alla nödvändiga ändamål för vår behandling är förknippade med att tillhandahålla våra produkter.

 - Registrering leder till att ditt användarkonto skapas med e-postadress och lösenord.
 - Tillhandahållandet av våra produkter kräver att du tillhandahåller CGM-uppgifter frivilligt.
 - Kommunikation från OneTwo Analytics i våra produkter eller via andra elektroniska meddelandetjänster (t.ex. e-post) när det krävs för att stödja eller felsöka våra produkter. Använd vår supporttjänst, [support@onetwo-diabetes.com](mailto:support@onetwo-diabetes.com), när du behöver komma i kontakt med oss. Tänk på vilken information och data du vill ge i din aktiva kommunikation med oss - det är bara ditt beslut. Vi kommunicerar med användare via e-post eller push-meddelande. Vi informerar vi dig om uppdateringar av vår produkt och ger viktiga säkerhetsråd samt hjälp i samband med din användning via e-post eller push-meddelande. Supportkommunikation är en viktig del av våra produkter som skickas till användare oavsett om de prenumererar på vårt nyhetsbrev eller inte.
 - Användning av vår produkt kräver att du anger dina CGM-data. För att lösa ett fel i appen kräver vi kraschrapporter som vi kan använda för felsökningsändamål för att fastställa problemets omständigheter.
 - Nyckeluppgifter för din enhet och ditt användningsbeteende registreras hos oss, det innebär att vi kan anpassa våra produkter, tex bearbeta individuell användarinformation som möjliggör relevant anpassning av användargränssnittet.

### 5.3 Bearbetning för produktförbättring
Vi behandlar också dina användardata för att förbättra våra produkter och tjänster, som beskrivs mer detaljerat nedan.

### 5.3.1 Användningsdata
Aktivitetshändelser gör att vi kan förstå hur du använder våra produkter. Detta gör det möjligt för oss att se hur våra produkter används och till exempel var menydesigner kan optimeras.

### 5.3.2 Syfte med produktförbättring
Som ett resultat av snabba tekniska framsteg måste vi kontinuerligt analysera, utveckla, testa och förbättra våra produkter och deras interaktioner för att säkerställa att vårt innehåll gynnar användarna på bästa sätt. För att uppnå detta genomför vi användnings- och säkerhetstester, och den kunskap som erhålls införlivas i förbättrade nya versioner av våra produkter, till exempel appen. Dessa förbättringar tillhandahålls också via regelbundna uppdateringar.

### 5.4 Bearbetning för marknadsföringsändamål
### 5.4.1 Nyhetsbrev
Vi vill skicka dig spännande information om produkter utöver det kontraktsmässiga omfånget (inklusive information från noggrant utvalda partners) och inbjudningar att delta i undersökningar eller andra säljfrämjande åtgärder och marknadsföringsaktiviteter ("Nyhetsbrev").

Du kan välja om du vill prenumerera på vårt nyhetsbrev (opt-in). Du kan när som helst återkalla ditt samtycke via länken i nyhetsbrevet eller kontoinställningarna.

### 5.4.2 Andra typer av marknadsföring
Andra samtycken, t.ex. för undersökningar eller meddelanden, erhålls efter behov när du är inloggad. Vi förklarar alltid varför vi behöver vissa uppgifter och hur du kan återkalla samtycket.

### 5.5 Användning för lagstadgade ändamål
### 5.5.1 Vetenskaplig forskning och statistik
OneTwo Analytics är engagerad i vetenskapen om alla aspekter av diabetes. Därför kan anonyma användardata också användas för forskning och statistik (alltid i enlighet med erkända etiska vetenskapliga standarder) och interna analyser. Detta används främst för att bestämma och förbättra effektiviteten hos tekniker för att kontrollera och behandla diabetes. Den rättsliga grunden för detta är artikel 9.2 j. i GDPR.

### 5.5.2 Verkställighet av rättigheter
Användningen av personuppgifter kan också vara nödvändig för att förhindra missbruk av användare eller för att hävda, utöva eller försvara rättsliga anspråk. Vi kan tvingas avslöja användaruppgifter på grund av bindande lagar, domstols- eller officiella beslut och instruktioner, brottsutredning eller i allmänhetens intresse. I sådana fall är lagring och behandling av dina uppgifter tillåtna enligt lag utan ditt samtycke. Den rättsliga grunden för detta är artikel 9.2 f. i GDPR.

## 6. HUR LÄNGE VI BEHÅLLER DINA DATA
OneTwo Analytics behandlar endast dina personuppgifter så länge det är nödvändigt för de ändamål för vilka informationen i fråga behandlas enligt avsnitt 5 ovan. Detta innebär att vi bara behåller information så länge det är nödvändigt för att kunna tillhandahålla produkterna eller uppfylla våra juridiska skyldigheter.

## 7. TREDJE PART
För att OneTwo Analytics ska kunna erbjuda produkterna använder vi externa leverantörer som behandlar personuppgifter i vissa fall, till exempel värdleverantörer. Dessa tjänsteleverantörer behandlar personuppgifter som databehandlare ("Processorer") och på uppdrag av OneTwo Analytics. De får endast behandla uppgifterna i syfte att tillhandahålla de tjänster som OneTwo Analytics begär, och i enlighet med OneTwo Analytics instruktioner.

## 8. ÖVERFÖRINGAR TILL TREDJE LÄNDER
OneTwo Analytics överför inga uppgifter till tredjeländer, nämligen länder utanför EU / EES. Detta kan förändras i framtiden. Överföring av personuppgifter till länder utanför EU / EES sker dock endast om överföringen är laglig enligt tillämplig dataskyddslagstiftning.

## 9. ALLMÄN INFORMATION
### 9.1 Syftebegränsning och säkerhet
OneTwo Analytics använder dina personuppgifter uteslutande för de ändamål som anges i denna Integritetspolicy och relevanta samtycken. Vi ser till att varje bearbetning är begränsad i den utsträckning som är nödvändig för dess ändamål. Varje behandling garanterar alltid tillräcklig säkerhet och konfidentialitet för dina personuppgifter. Detta täcker skydd mot obehörig och olaglig behandling, oavsiktlig förlust, oavsiktlig förstörelse eller skada med lämpliga tekniska och organisatoriska åtgärder. Vi använder strikta interna processer, säkerhetsfunktioner och krypteringsmetoder.

### 9.2 Processorer
Vi använder endast processorer som erbjuder tillräckliga garantier för att lämpliga tekniska och organisatoriska åtgärder vidtas på ett sätt som behandlingen av personuppgifter uppfyller de lagstadgade kraven och vår integritetspolicy. Skyddet av våra användares rättigheter säkerställs genom att ingå bindande avtal som uppfyller de strikta kraven i GDPR.

Tredjepartsleverantörer som utsetts av OneTwo Analytics får endast använda andra processorer (underleverantörer) med vårt tidigare samtycke. Om en underleverantör inte uppfyller samma skyldigheter för dataskydd och alla lämpliga säkerhetsåtgärder som vi inför våra processorer, kommer vi att förbjuda att anställa en sådan underleverantör.

### 9.3 Kryptering, pseudonymisering och anonymisering
Varje dataöverföring, utan undantag och som standard, krypteras under överföringen. Med hjälp av HTTPS (hypertextöverföringsprotokoll säkert) ser vi till att obehöriga tredje parter inte fångar upp dina uppgifter

För datasäkerhet och minimeringsändamål använder vi också andra processer för kryptering och pseudonymisering av användardata. Naturligtvis beror detta på typen av, omfattning och syfte med relevant databehandling. Till exempel avslöjar vi bara användardata som en processor behöver för att utföra sina uppgifter.

När ett avtalsförhållande med en processor avslutas, måste en sådan processor, enligt OneTwo Analytics bedömning, antingen returnera all vår användardata eller radera den om det inte finns lagstadgade lagringsskyldigheter.

Uppgifter som inte kräver någon personlig referens för bearbetning (t.ex. för forskning och analys) anonymiseras.

## 10. DINA RÄTTIGHETER
Du har många rättigheter relaterade till de personuppgifter vi behandlar om dig, baserat på tillämplig dataskyddslagstiftning. Du kan när som helst kontakta oss för att:

 - Begära åtkomst till och information om de personuppgifter som behandlas av oss (rätt till åtkomst),
 - "Rätt att glömmas bort." Varje användare har rätt att begära radering av sina personuppgifter. För att göra det, vänligen kontakta oss när som helst på [support@onetwo-diabetes.com](mailto:support@onetwo-diabetes.com),
 - Be oss att begränsa behandlingen av dina personuppgifter om du anser att sådana uppgifter är felaktiga, om vår behandling är olaglig eller om vi inte längre behöver behandla sådana uppgifter för ett visst ändamål såvida vi inte är skyldiga att behålla sådana på grund av en eller annan skyldighet,
 - Motsätta dig behandlingen av dina personuppgifter där den juridiska motiveringen för vår behandling av dina personuppgifter är vårt legitima intresse. Vi kommer att följa din begäran om vi inte har en övertygande legitim grund för behandlingen som åsidosätter ditt intresse eller rättigheter, eller om vi behöver behandla sådana uppgifter för eventuella rättsliga anspråk,
 - Återkalla ditt samtycke via kontoinställningarna eller genom att skicka ett e-postmeddelande till [support@onetwo-diabetes.com](mailto:support@onetwo-diabetes.com), och
 - Begära att dina personuppgifter flyttas till en annan personuppgiftsansvarig genom att ta emot dina personuppgifter, i den utsträckning de har tillhandahållits av dig, i ett elektroniskt format som vanligtvis används för att kunna överföra dem till en annan part (rätten till dataportabilitet).

Om du vill kontakta oss angående någon av de rättigheter som nämns ovan, vänligen kontakta oss via vår webbplats eller genom att skicka ett e-postmeddelande till [support@onetwo-diabetes.com](mailto:support@onetwo-diabetes.com).

## 11. RÄTT ATT REGISTRERA ETT KLAGOMÅL
Om du anser att behandlingen av dina personuppgifter är felaktig eller inte överensstämmer med de juridiska kraven, har du rätt att lämna in ett klagomål till relevant dataskyddsmyndighet. I Sverige är relevant dataskyddsmyndighet Svenska Integritetsskyddsmyndigheten (IMY).

## 12. KONTAKT
Vi hoppas att denna Integritetspolicy har gjort det klart hur vi hanterar dina personuppgifter och har svarat på eventuella frågor du hade. Men om du har några frågor är du alltid välkommen att kontakta oss via vår webbplats eller genom att skicka ett e-postmeddelande till [support@onetwo-diabetes.com](mailto:support@onetwo-diabetes.com). Vi kommer att hantera din begäran så snart som möjligt.

`
