import React from "react"
import Modal from "react-modal"

import "./OneTwoModal.css"

Modal.setAppElement("#root")

export default function DexcomModal({ modalIsOpen, closeModal, children }) {
  return (
    <div>
      <Modal
        className="Modal"
        overlayClassName="Overlay"
        isOpen={modalIsOpen}
        onRequestClose={() => closeModal(false)}
        shouldCloseOnOverlayClick={false}
      >
        <div>{children}</div>
      </Modal>
    </div>
  )
}
