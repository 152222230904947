import React, { useState } from "react"
import "./LanguageDropdown.css"
import { useTranslation } from "react-i18next"
import { faGlobe } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button } from "./Button"

export default function Dropdown() {
  const { t, i18n } = useTranslation()

  const changeLanguageHandler = (lang) => {
    i18n.changeLanguage(lang)
  }
  return (
    <div className="dropdown">
      <Button buttonStyle="btn--dropdown" buttonSize="btn--small">
        <FontAwesomeIcon icon={faGlobe} className="nav-globe-icon" />
      </Button>
      <div className="dropdown-content">
        <div
          className="language"
          onClick={() => {
            changeLanguageHandler("sv")
          }}
        >
          {t("languages.sv")}
        </div>
        <div
          className="language"
          onClick={() => {
            changeLanguageHandler("en")
          }}
        >
          {t("languages.en")}
        </div>
        <div
          className="language"
          onClick={() => {
            changeLanguageHandler("dk")
          }}
        >
          {t("languages.dk")}
        </div>
        <div
          className="language"
          onClick={() => {
            changeLanguageHandler("fi")
          }}
        >
          {t("languages.fi")}
        </div>
      </div>
    </div>
  )
}
