import React from "react"

import "./Loading.css"

const STYLES = ["white", "onetwo--primary", "weak-white"]

export function Loading({ color, context }) {
  const checkStyle = STYLES.includes(color) ? color : STYLES[0]

  return (
    <div className="loading-container">
      <div className={context === "loading-data" ? "loading-data" : "loading"}>
        <div className={`dash uno ${checkStyle}`}></div>
        <div className={`dash dos ${checkStyle}`}></div>
        <div className={`dash tres ${checkStyle}`}></div>
        <div className={`dash cuatro ${checkStyle}`}></div>
      </div>
    </div>
  )
}

export function SmallLoading({ color, context }) {
  const checkStyle = STYLES.includes(color) ? color : STYLES[0]

  return (
    <div className="small-loading-container">
      <div className={"small-loading"}>
        <div className={`small-dash small-uno ${checkStyle}`}></div>
        <div className={`small-dash dos ${checkStyle}`}></div>
        <div className={`small-dash tres ${checkStyle}`}></div>
        <div className={`small-dash cuatro ${checkStyle}`}></div>
      </div>
    </div>
  )
}
