import axios from "./axios"

export const fetchDataForProfile = async (token, profileId) => {
  try {
    const response = await axios.post(
      "/v1/patient/" + profileId + "/data",
      "",
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      }
    )
    return response.data
  } catch (err) {
    throw err
  }
}

export async function deleteDataForProfile(token, patientId) {
  try {
    const response = await axios.delete("/v1/patient/" + patientId + "/data", {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
    return response.data
  } catch (err) {
    throw err
  }
}

export async function addNightscoutForProfile(
  authtoken,
  patientId,
  url,
  accessToken
) {
  let dataService = { url: url, accessToken: accessToken }
  try {
    const response = await axios.post(
      "/v1/patient/" + patientId + "/data_service/nightscout",
      dataService,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authtoken,
        },
      }
    )
    return response.data
  } catch (err) {
    throw err
  }
}

export async function removeDataSourceForProfile(
  authtoken,
  patientId,
  dataServiceId
) {
  try {
    const response = await axios.delete(
      "/v1/patient/" + patientId + "/data_service/" + dataServiceId,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authtoken,
        },
      }
    )
    return response.data
  } catch (err) {
    throw err
  }
}

export const activateDataSourceForProfile = async (
  token,
  patientId,
  dataServiceId
) => {
  try {
    const response = await axios.put(
      "/v1/patient/" +
        patientId +
        "/data_service/" +
        dataServiceId +
        "/activate",
      "",
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      }
    )
    return response.data
  } catch (err) {
    throw err
  }
}
