import React from "react"
import { useTranslation } from "react-i18next"

import { Button } from "../../Button"

export function UploadFileList({ fileList, resetFileDrop, isLoading }) {
  const { t } = useTranslation()
  // const status = ["RECEIVED", "UPLOADED", "ERROR"]
  return (
    <div>
      <div className="file-list">
        {fileList.map((item, index) => (
          <div
            className={
              item.status === "RECEIVED"
                ? null
                : item.status === "UPLOADED"
                ? "file-item success"
                : "file-item danger"
            }
            key={index}
          >
            <p className="filename"> {item.file.name}</p>
            <p className="message"> {t("errors." + item.message)}</p>
            <i
              className={
                item.status === "ERROR"
                  ? "onetwo-icon upload-icon icon-error"
                  : "onetwo-icon upload-icon icon-success"
              }
            >
              {t("icons." + item.icon)}
            </i>
          </div>
        ))}
      </div>
      <div className="button-container flex-center">
        {isLoading ? null : (
          <Button
            buttonStyle="btn--primary"
            buttonSize="btn--small"
            onClick={resetFileDrop}
          >
            {t("home.data.upload.moreFiles")}
          </Button>
        )}
      </div>
    </div>
  )
}

export default UploadFileList
