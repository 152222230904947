import { Routes, Route, Navigate, useLocation } from "react-router-dom"
import React, { useEffect } from "react"
import ReactGA from "react-ga"
import "./App.css"

import RequireAuth from "./component/RequireAuth"
import Layout from "./component/Layout"
import Home from "./page/Home"
import Settings from "./page/Settings"
import Missing from "./page/Missing"
import SignIn from "./page/SignIn"
import PersistLogin from "./component/PersistLogin"
import Unauthorized from "./page/Unauthorized"
import Dexcom from "./page/Dexcom"
import i18n from "./i18n"
import MarkdownBox from "./component/public/MarkDownBox"

const getNavigatorLanguage = () => {
  let browserLanguage = "en"
  if (navigator.languages && navigator.languages.length) {
    browserLanguage = navigator.languages[0]
  } else {
    browserLanguage =
      navigator.userLanguage ||
      navigator.language ||
      navigator.browserLanguage ||
      "en"
  }
  let languageKey = browserLanguage.substring(0, 2)
  if (languageKey === "en" || languageKey === "sv" || languageKey==="dk" || languageKey === "fi") return languageKey
  return "en"
}

function App() {
  console.log(
    "Starting app connected to API: " + process.env.REACT_APP_DDA_API_HOST
  )

  useEffect(() => {
    i18n.changeLanguage(getNavigatorLanguage())

    ReactGA.initialize(process.env.REACT_APP_DDA_GA_MEASUREMENT_ID || "G-example")
  }, [])

  const location = useLocation()
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location })
  }, [location])

  return (
    <Routes>
      <Route element={<PersistLogin />}>
        <Route path="/" element={<Layout />}>
          {/* public routes */}
          <Route path="" element={<Navigate to="/sign-in" />} />
          <Route
            path="/privacy/:language"
            element={<MarkdownBox legalDocumentType={"privacy"} />}
          />
          <Route
            path="/terms/:language"
            element={<MarkdownBox legalDocumentType={"terms"} />}
          />

          <Route path="sign-in" element={<SignIn />} />
          <Route path="unauthorized" element={<Unauthorized />} />
          <Route path="dexcom" element={<Dexcom />} />

          {/* protected routes */}
          <Route element={<RequireAuth allowedRoles={["USER"]} />}>
            <Route path="home" element={<Home />} />
            <Route path="settings" element={<Settings />} />
          </Route>

          {/* catch all */}
          <Route path="*" element={<Missing />} />
        </Route>
      </Route>
    </Routes>
  )
}

export default App
