import axios from "./axios"

const uploadFile = async (file, patientId, token) => {
  let formData = new FormData()
  formData.append("file", file)
  try {
    const response = await axios.post("/v1/patient/" + patientId + "/file", formData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
    return response.data
  } catch (err) {
    throw err
  }
}

export default uploadFile
