export const THIS_YEAR = new Date().getFullYear()
export const T1_TREATMENTS = ["INSULIN_BY_PEN", "INSULIN_BY_PUMP"]
export const T2_TREATMENTS = ["DIET_EXERCISE", "ORAL_ANTIDIABETIC_DRUGS", "LONG_ACTING_INSULIN", "MEAL_INSULIN", "GLP1_ANALOGUE",]

export const TREATMENT_ID = {
  INSULIN_BY_PEN: 1,
  INSULIN_BY_PUMP: 2,
  DIET_EXERCISE: 6,
  ORAL_ANTIDIABETIC_DRUGS: 7,
  LONG_ACTING_INSULIN: 8,
  MEAL_INSULIN: 9,
  GLP1_ANALOGUE: 10,
}

export const DIABETES_TYPES = [
  "NOT_SET",
  "DIABETES_TYPE1",
  "DIABETES_TYPE2",
  "DIABETES_OTHER",
]
export const DIABETES_TYPE_ID = {
  DIABETES_TYPE1: 1,
  DIABETES_TYPE2: 2,
  DIABETES_OTHER: 3,
}

export const GENDER_TYPES = ["UNKNOWN", "MALE", "FEMALE", "OTHER"]
export const GENDER_ID = {
  UNKNOWN: 4,
  MALE: 1,
  FEMALE: 2,
  OTHER: 3,
}
