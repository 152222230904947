import { Outlet } from "react-router-dom"
import { useState, useEffect } from "react"
import useRefreshToken from "../hooks/useRefreshToken"
import useAuth from "../hooks/useAuth"
import LoadingPage from "../page/LoadingPage"


const PersistLogin = () => {
  const [isLoading, setIsLoading] = useState(true)
  const refresh = useRefreshToken()
  const { auth } = useAuth()

  useEffect(() => {
    const verifyRefreshToken = async () => {
      try {
        // console.log("refrsh");
        await refresh()
        localStorage.setItem("loggedIn", JSON.stringify(true))
        // await new Promise(r => setTimeout(r, 2000));
      } catch (err) {
        localStorage.setItem("loggedIn", JSON.stringify(false))
        console.log("No previous session found.")
      } finally {
        setIsLoading(false)
      }
    }
    // console.log("Persisence login check: " + JSON.parse(localStorage.getItem("loggedIn")));
    // console.log("part two" + !auth?.accessToken);
    // If no accessToken found in auth and loggedIn status from local storage is true, get a new auth token.
    !auth?.accessToken && JSON.parse(localStorage.getItem("loggedIn"))
      ? verifyRefreshToken()
      : setIsLoading(false)
  }, [])

  return (
    <>
      {isLoading ? (
        <LoadingPage />
      ) : (
        <Outlet />
      )}
    </>
  )
}

export default PersistLogin
