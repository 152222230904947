import React, { useState } from "react"
import { useTranslation } from "react-i18next"

import useAuth from "../../hooks/useAuth"
import useProfiles from "../../hooks/useProfiles"
import ProfileCardHeader from "./ProfileCardHeader"
import ProfileCardBody from "./ProfileCardBody"
import ProfileCardForm from "./ProfileCardForm"
import ProfileCardData from "./ProfileCardData"

import {
  dbPostProfile,
  dbPutProfile,
  dbDeleteProfile,
} from "../../api/profiles"

import "./ProfileCard.css"

export const ProfileCard = ({ profile, isActive, setActiveCard }) => {
  const { t } = useTranslation()
  const { auth } = useAuth()
  const { profiles, setProfiles } = useProfiles()
  const cardTitle = profile
    ? profile.firstName + " " + profile.lastName
    : t("home.newProfile")

  const [uploadMode, setUploadMode] = useState(false)
  const [editMode, setEditMode] = useState(!profile)

  const profileForUserExists =
    auth.user.patientRelations.map((po) => po.relation.key).indexOf("SELF") >= 0

  const updateProfile = async (profileId, profileData) => {
    const response = await dbPutProfile(profileId, profileData, auth.accessToken)
    setProfiles(profiles.map((p) => (p.id === response.id ? response : p)))
  }

  const createProfile = async (profileData) => {
    const response = await dbPostProfile(profileData, auth.accessToken)
    setProfiles([...profiles, response])
    setActiveCard(response.id)
  }

  const deleteProfile = async (id) => {
    const response = await dbDeleteProfile(id, auth.accessToken)
    setProfiles(profiles.filter((p) => p.id !== id))
  }

  return (
    <div className={isActive ? "card active" : "card"}>
      <ProfileCardHeader
        profileId={profile?.id}
        title={cardTitle}
        subtitle={profile?.nationalId}
        isActive={isActive}
        setActive={() => setActiveCard(isActive ? null : profile?.id || 0)}
      ></ProfileCardHeader>
      {isActive ? (
        <ProfileCardBody>
          {uploadMode ? (
            <ProfileCardData profile={profile} setUploadMode={setUploadMode} />
          ) : (
            <ProfileCardForm
              profile={profile}
              profileForUserExists={profileForUserExists}
              updateProfile={updateProfile}
              createProfile={createProfile}
              deleteProfile={deleteProfile}
              editMode={editMode}
              setEditMode={setEditMode}
              setUploadMode={setUploadMode}
            />
          )}
        </ProfileCardBody>
      ) : (
        <></>
      )}
    </div>
  )
}
