import { useParams } from "react-router-dom"

import ReactMarkdown from "react-markdown"
import remarkGfm from "remark-gfm"
import "./MarkDownBox.css"
import { assets } from "../../assets/Assets"
import { privacy_policy_en } from "../../assets/md/privacy_policy_en.js"
import { privacy_policy_sv } from "../../assets/md/privacy_policy_sv.js"
import { terms_of_use_en } from "../../assets/md/terms_of_use_en.js"
import { terms_of_use_sv } from "../../assets/md/terms_of_use_sv.js"
import { Button } from "../Button"

export default function MarkdownBox({ legalDocumentType }) {
  const { language } = useParams()
  let legalDocument = ""
  if (legalDocumentType === "privacy") {
    if (language === "en") {
      legalDocument = privacy_policy_en
    } else if (language === "sv") {
      legalDocument = privacy_policy_sv
    } else {
      legalDocument = privacy_policy_en
    }
  }
  if (legalDocumentType === "terms") {
    if (language === "en") {
      legalDocument = terms_of_use_en
    } else if (language === "sv") {
      legalDocument = terms_of_use_sv
    } else {
      legalDocument = terms_of_use_en
    }
  }
  return (
    <div>
      <Button className="btn btn--primary btn--small" linkTo={"/"}>
        Back
      </Button>
      <div className="markdown-wrapper">
        <div className="markdown-header">
          <img
            className="legal-logo"
            src={assets.onetwoDiabetes}
            alt={"Onetwo Diabetes logo"}
          />
          <img
            className="legal-logo"
            src={assets.onetwoAnalytics}
            alt={"Onetwo Analytics logo"}
          />
        </div>
        <ReactMarkdown
          className={"markdown"}
          remarkPlugins={[remarkGfm]}
          children={legalDocument}
        />
      </div>
    </div>
  )
}
