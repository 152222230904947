export const terms_of_use_sv = `14 december, 2022
OneTwo Analytics AB

# Användarvillkor

Version: 1.1.5

## 1. Introduktion
OneTwo Diabetes är en produkt som ges ut av bolaget OneTwo Analytics AB (”OneTwo Analytics”). Dessa användarvillkor (”villkor”) avser användningen av denna produkt, både som mobilapp och på webben. Villkoren gäller mellan OneTwo Analytics och dig som användare när du besöker vår webbplats eller använder någon av våra produkter eller tjänster (tillsammans "produkterna").

Vi vill betona att vår produkt endast ska användas för att ge dig information för att öka medvetenheten om din diabetes, men den kan inte ersätta samråd med och diagnos av vårdpersonal. Under inga omständigheter ger OneTwo Diabetes rekommendationer om medicinsk terapi eller medicinsk rådgivning av något slag. För frågor om sjukdom och terapi rekommenderar vi att du kontaktar din vårdgivare. Uppgifterna får endast analyseras av dig och din vårdgivare. Inget innehåll - oavsett om det tillhandahålls av OneTwo Diabetes, våra samarbetspartners eller användare - kan vara komplettering eller ersättning av information från vårdgivare.

OneTwo Diabetes ska inte användas i en nödsituation. I händelse av en nödsituation, kontakta närmaste räddningsenhet eller ring 112.

Läs igenom dessa villkor noggrant. Tillgång till och användning av OneTwo Diabetes är villkorat av att du godkänner dessa villkor. Du måste läsa, godkänna och acceptera alla villkor som finns i dessa villkor. Genom att använda vår produkt accepterar du dessa villkor. Om du har några frågor relaterade till dessa villkor är du välkommen att kontakta OneTwo Diabetes på [support@onetwo-diabetes.com](mailto:support@onetwo-diabetes.com).

OneTwo Analytics är ett svenskt företag med huvudkontor på Kungsgatan 84, 112 27 Stockholm. Bolaget arbetar för att förbättra livet för personer med diabetes och stöder vårdgivare som tillhandahåller vård för dessa personer. Produkterna tillhandahåller en sekundär analys av data från en kontinuerlig glukosövervakning på en lämplig enhet (telefon eller läsplatta). Produkten ersätter inte kontinuerlig glukosövervakning i realtid eller vanlig blodglukosövervakning i hemmet. För eventuella medicinska beslut om din hälsa, kontakta din läkare och använd din kontinuerliga glukosmonitor (CGM).

## 2. Ändringar av villkoren
Vi förbehåller oss uttryckligen rätten att göra framtida ändringar av våra villkor när vi anser det vara nödvändigt. Ändringar kan till exempel vara nödvändiga för att uppfylla lagstadgade krav, motsvara tekniska och ekonomiska krav eller för att tillgodose våra användares intressen.

Väsentliga förändringar av våra villkor kommer att informeras till våra användare innan de träder i kraft. Mindre ändringar av våra villkor kommer inte att informeras till våra användare innan de träder i kraft. Vi rekommenderar därför att alla användare regelbundet läser den senaste och aktuella versionen av villkoren.

Användningen av vår produkt kräver din registrering hos OneTwo Diabetes i våra kanaler eller där appar tillhandahålls När du har aktiverat vår produkt för första gången måste du registrera dig med en e-postadress och lösenord. Genom att registrera sig godkänner användaren att ingå ett avtal med OneTwo Analytics för användning av vår produkt OneTwo Diabetes. Vi accepterar uttryckligen ett sådant erbjudande genom att aktivera ditt användarkonto.

Aktiveringen bekräftas direkt efter registreringen genom att låta dig använda vår produkt. Vi förbehåller oss rätten att avvisa enskilda användare eller deras erbjudande att ingå ett avtal utan att ange skäl. I ett sådant fall kommer alla tillhandahållna användardata att raderas omedelbart.

Med din registrering och aktivering förvärvar du den icke-exklusiva, icke-överförbara men geografiskt obegränsade rätten att spara och använda en kopia av vår produkt eller app för dina egna ändamål inom ramen för dessa villkor. Du kan använda vår gratisprodukt utan tidsbegränsning. Om du har frågor om din användningsperiod eller dess slut, vänligen kontakta [support@onetwo-diabetes.com](mailto:support@onetwo-diabetes.com).

Vid registrering bekräftar användaren sitt godkännande och obegränsade godkännande av innehållet i dessa villkor och att informationen är sann, korrekt, uppdaterad och fullständig. Användare är skyldiga att regelbundet uppdatera sin personliga information. OneTwo Diabetes kan ändra eller ta bort all information som den anser vara olämplig eller olaglig eller på annat sätt sannolikt kommer att utsätta OneTwo Diabetes för anspråk från tredje part. Du samtycker till att vi kan vidta åtgärder för att verifiera riktigheten av den information som du har gett oss. Vi förbehåller oss rätten att när som helst kontakta användare för att verifiera registreringsdata och användningsinformation.

Om en användare tillhandahåller falsk, felaktig, inaktuell eller ofullständig information eller om vi har anledning att tro att informationen är falsk, felaktig, inaktuell eller ofullständig, har OneTwo Diabetes rätt att blockera det relevanta användarkontot med omedelbar verkan utan föregående meddelande och förbjuder användningen av vår produkt utan att vara skyldig att betala tillbaka användaren för eventuella kostnader.

Du är ansvarig för att upprätthålla säkerheten för ditt användarkonto. Dessutom är du fullt ansvarig för alla aktiviteter som sker under kontot och alla andra åtgärder som vidtas i samband med kontot.

Du är ansvarig för att vidta rimliga åtgärder för att behålla sekretessen för ditt användarnamn och lösenord. Du måste omedelbart meddela OneTwo Diabetes på [support@onetwo-diabetes.com](mailto:support@onetwo-diabetes.com) om obehörig användning av din information, ditt konto eller andra säkerhetsöverträdelser. OneTwo Diabetes ansvarar inte för några handlingar eller utelämnanden från dig, inklusive skador av något slag som uppkommit till följd av sådana handlingar eller utelämnanden.

Användaren måste skydda sitt användarkonto och registreringsdata från missbruk och användning med bedräglig avsikt. Om det till och med finns misstankar om att det har utsatts för sådana risker måste detta utan dröjsmål meddelas till [support@onetwo-diabetes.com](mailto:support@onetwo-diabetes.com). Vi har rätt att blockera användarens användarkonto om det används för felaktiga eller bedrägliga avsikter.

Vi förbehåller oss rätten att tillfälligt blockera användare när som helst av någon viktig anledning (enligt vår bedömning) eller att permanent utesluta dem från att använda vår produkt.

Vad som utgör en viktig orsak och om det anses ha inträffat bedöms av OneTwo Diabetes.

## 3. Användarens ansvar
Varje användare har rätt och skyldighet att använda vår produkt på egen risk och bekostnad på lämplig teknisk utrustning. De enheter och operativsystem som är kompatibla med produkten finns i appbutikerna eller kan när som helst begäras från [support@onetwo-diabetes.com](mailto:support@onetwo-diabetes.com).

Du är skyldig att använda vår produkt uteslutande i enlighet med dessa villkor och lagstadgade bestämmelser samt undvika all felaktig användning. Meddela oss omedelbart om du upptäcker brott mot de uppgifter som anges i detta avsnitt på [support@onetwo-diabetes.com](mailto:support@onetwo-diabetes.com).

Varje användare är skyldig att ange sina registreringsuppgifter på ett sanningsenligt sätt, att hålla den uppdaterad och fullständig samt att inte vidarebefordra den till någon tredje part. Personuppgifter måste behandlas konfidentiellt, hanteras noggrant och säkerhetskopieras under eget ansvar. OneTwo Diabetes tar inget ansvar eller andra garantier för förlorade eller skadade data eller innehåll.

Innehåll som foton, bilder, texter, videor eller andra bilder får endast lagras, publiceras, överföras eller distribueras i samband med vår produkt om användaren har rätt att överföra eller använda dem. I alla fall är användning av rasistiskt, stötande, diskriminerande, förtalande, sexuellt, pornografiskt, våldsamt eller annat olagligt innehåll av alla slag alltid förbjudet.

Det är förbjudet att dekryptera, omvandla, dekompilera eller ta isär vår produkt. Varje användare är skyldig att inte genomföra några tekniska eller elektroniska störningar i vår produkt eller nätverk av OneTwo Diabetes (i synnerhet hackningsförsök, brutala kraftattacker, införande av virus, maskar, trojanska hästar, annan skadlig programvara) eller någon form av försök till störningar som kan påverka programvaran eller hårdvaran för OneTwo Diabetes produkt och system.

## 4. OneTwo Analytics ansvar
Om inte annat anges i dessa villkor är OneTwo Analytics ansvarig och ger garantier enligt lagstadgade bestämmelser. När det gäller användare som inte är konsumenter utesluts ansvar för ekonomiska förluster, följdskador, förlorad vinst och skador till följd av krav från tredje part. Om vårt ansvar är begränsat eller uteslutet i dessa villkor gäller detta också för vikarierade agenter och anslutna företag i OneTwo Analytics.

Utanför tillämpningsområdet för produktansvarslagstiftningen är OneTwo Analytics ansvar begränsat till avsiktliga handlingar och grov vårdslöshet - förutom personskada. Ansvaret för lätt vårdslöshet utesluts när det ställs inför objektiva motiveringar.

OneTwo Analytics ger ingen garanti för att vår produkt ska vara helt tillgänglig utan avbrott och utan fel eller att den nödvändiga programvaran och hårdvaran fungerar utan fel. Vi kan inte heller utesluta möjligheten att data kan spåras, spelas in eller förfalskas av tredje part under dataöverföring via tredjepartssystem, särskilt Internet och andra telenät.

OneTwo Analytics ger ingen garanti för nedladdat innehåll eller material som användarna har fått på grund av att de använder våra produkter. Användaren är ensam ansvarig för all skada som kan skapas i deras IT-system eller enheter eller för förlust av data på grund av nedladdning av material som är associerade med våra produkter.

OneTwo Analytics ansvarar inte för innehåll från tredje part som externa länkar, banners, annan information eller reklamerbjudanden från tredje part som kan placeras som en del av våra produkter. Om vi möjliggör tillgång till erbjudanden från tredje part via meddelanden eller länkar, är OneTwo Analytics inte ansvarig för informationen i den. Resulterande juridiska transaktioner med tredje part leder uteslutande till avtalsförhållanden mellan användaren och relevant tredje part. Vi ger ingen garanti och accepterar inget annat ansvar för produkter från tredje part.

Användningen av våra produkter ersätter inte samråd med vårdgivare eller annan medicinsk rådgivning och sker uteslutande på användarens egen risk. Detta gäller användning av data som tillhandahålls som en del av våra produkter inklusive blodglukosresultat, förutsägelser, beräkningar och eventuella rekommendationer. Användaren medger uttryckligen att sådan information kan vara defekt, och OneTwo Analytics tar inget ansvar för att de är korrekta.

I den utsträckning som krävs enligt tillämplig lagstiftning om medicintekniska produkter som reglerar användningen av produkten får produkten endast användas eller användas uteslutande enligt syftet, specifikationerna och applikationsområdena som anges i erbjudandet och användningsvillkoren.

## 5. Tillgängligheten för våra produkter
Vi tillhandahåller våra produkter i varje fall i enlighet med befintliga tekniska, ekonomiska, operativa och organisatoriska möjligheter. OneTwo Analytics kan inte utesluta några avbrott, störningar, förseningar, raderingar, felaktiga överföringar eller lagringsfel i samband med användning av vår produkt eller kommunikation med användare. Vi erbjuder delvis vår produkt i samarbete med tredjepartsleverantörer och är därför också beroende av tillgången till tredjepartsprodukter. Därför accepterar OneTwo Analytics inget ansvar eller skyldighet samt ger inga garantier för att vår produkt tillhandahålls online hela tiden utan avbrott.

Föregående stycke gäller också för begränsningar vid användning av vår produkt på grund av force majeure, strejker, lockouts och officiella instruktioner eller på grund av tekniska ändringar eller underhållsarbete på OneTwo Analytics-systemen. Vid störningar eller fel på vår produkt, vänligen kontakta [support@onetwo-diabetes.com](mailto:support@onetwo-diabetes.com) när som helst.

## 6. Inköp och betalningar
Vår produkt, OneTwo Diabetes, kan nås eller laddas ner på AppStore och Google Play och kräver ett användarkonto.

Vi förbehåller oss uttryckligen rätten att när som helst begränsa gratisprodukter, stoppa dem helt eller ta ut en avgift för dem. Användaren har ingen laglig rättighet att i framtiden få fri användning av enskilda produkter eller delar därav under en viss period.

OneTwo Analytics kan också göra speciella och kostnadsfria erbjudanden som är föremål för ytterligare villkor eller begränsningar. I det avseendet hänvisar vi uttryckligen till eventuella avvikelser från dessa villkor medan annars är hela innehållet i dessa villkor fortfarande tillämpligt. Specialerbjudanden eller gratiserbjudanden kan inte överföras till andra användare.

Konfigurationer och priser på produkten kan ändras när som helst, och OneTwo Analytics har alltid rätt att ändra konfigurationer, avgifter, priser och offert. Du samtycker till sådana ändringar om du inte skriftligen motsätter dig OneTwo Analytics inom sju (7) arbetsdagar efter att du fått ett meddelande från OneTwo Analytics, eller en faktura, som innehåller eller meddelar avgiften och/eller prisändringar.

Alla priser är exklusive moms och du ska betala alla skatter, tullar, avgifter eller andra liknande avgifter som läggs på OneTwo Analytics eller dig själv av någon skattemyndighet (andra än skatter som läggs på OneTwo Analytics intäkter), relaterade till din beställning, såvida du inte har försett OneTwo Analytics med ett lämpligt återförsäljnings- eller undantagscertifikat för leveransplatsen, som är den plats där produkterna används eller utförs. I händelse av lagändringar så att en skatt tas ut som är eller blir återvinningsbar med en därmed ökad kostnad för OneTwo Analytics för att leverera produkten, varigenom och i sådan utsträckning OneTwo Analytics har rätt att höja sina priser därefter och med retroaktiv verkan.

## 7. Uppsägning
Du kan säga upp ditt avtal och stänga ditt konto hos OneTwo Analytics när som helst, med början den sista dagen i din prenumerationsperiod, genom att skicka ett e-postmeddelande till [support@onetwo-diabetes.com](mailto:support@onetwo-diabetes.com). OneTwo Analytics kan säga upp sin relation med dig eller när som helst säga upp eller avbryta tillgängligheten till produkten, inklusive användning av programvara om du bryter mot dessa villkor och/eller något annat avtal med OneTwo Analytics.

## 8. Tredjepartsleverantörer
Du hittar våra produkter på vår hemsida (onetwo-diabetes.com) eller i appbutikerna. I allmänhet gäller affärsvillkoren för den relevanta tredjepartsleverantören, som vi inte har något inflytande över. Dessa är inte en del av våra villkor eller vårt avtalsförhållande med dig som användare av våra produkter.

Vi arbetar med olika partners för att säkerställa optimal användning av vår produkt. Dessa inkluderar samarbetspartners som levererar oss eller våra användare direkt med vissa produkter (till exempel kontinuerliga glukosmätare). I dessa fall betonar vi uttryckligen de tillämpliga affärsvillkoren för tredjepartsleverantören som du måste acceptera innan du kan förvärva en av våra produkter via en tredjepartsleverantör.

## 9. Integritetsskydd
Vi hänvisar till vårt integritetsmeddelande för detaljerad information om hur vi behandlar dina personuppgifter inklusive tydliga instruktioner om dina rättigheter och vidare hur du kan utöva sådana rättigheter.

## 10. Upphovsrätt
Programvaran, koden, metoderna och systemen samt innehållet i våra produkter är skyddade av upphovsrätt och konkurrenslagstiftning och får endast användas av OneTwo Analytics. Vår produkt eller delar därav får inte kopieras, modifieras, reproduceras, publiceras på nytt, publiceras, överföras, säljas, erbjudas till försäljning, säljas på nytt eller användas på något annat sätt utan vårt föregående, skriftliga medgivande. Användare får inte använda varumärken, logotyper, andra kommersiella äganderättigheter eller OneTwo Diabetes varumärkesrättigheter. Om inte annat anges i dessa villkor ägs alla användnings- och exploateringsrättigheter uteslutande av OneTwo Analytics eller ett anslutet företag och det finns ingen licensiering av något slag för våra produkter.

Om detta krävs för det tekniska tillhandahållandet av vår produkt, ger varje användare OneTwo Diabetes en icke-exklusiv, återkallbar men fri, överförbar exploaterings- och användningsrätt, obegränsad med avseende på tid och plats, till innehållet som de genererar, överför, lagrar, eller publicera inom våra produkter. Användning eller exploatering är dock uteslutet om detta oproportionerligt påverkar användarens legitima intressen (till exempel integritetsrättigheter). Vid användning utanför vår produkt, om så är lämpligt, kommer vi att ange att innehållet kommer från användaren. OneTwo Diabetes gör inte anspråk på något ägande av skapat innehåll och kommer inte att ta någon övervakningsfunktion med avseende på innehåll som skapats av användare.

## 11. Ändringar av produkter
Konfigurationerna och specifikationerna på vår webbplats, inklusive utan begränsning allt innehåll som finns tillgängligt, och produkten kan ändras och/eller uppdateras, enligt OneTwo Diabetes bedömning. Användarna är bundna av sådana ändringar eller uppdateringar.

## 12. Skadestånd
Användaren accepterar fullt ansvar för alla skador och tvister inom och utanför domstol som uppstår på grund av konflikter med andra användare. Användaren medger uttryckligen att OneTwo Analytics under inga omständigheter ansvarar för handlingar eller utelämnanden från andra användare och eventuella skador som uppstår.

I händelse av anspråk mot OneTwo Diabetes från tredje part är användaren skyldig att omedelbart tillhandahålla all information som är tillgänglig för dem på ett sanningsenligt och fullständigt sätt som krävs för att granska, försvara och förhindra anspråk från tredje part.

## 13. Övrigt
Dessa villkor och hela det rättsliga förhållandet mellan OneTwo Diabetes och användaren är underkastad svensk lag, och domstolarna i Sverige har exklusiv behörighet att pröva eventuella anspråk som härrör från dem. Som konsument åtnjuter dock användaren skyddet av obligatoriska bestämmelser i det land där de är bosatta eller vanligt bosatta.

Avtalet mellan användaren och oss, som anges i dessa villkor, är personligt varför användaren inte får överföra dessa till en annan person utan ett föregående skriftligt medgivande från oss. Endast användaren själv kan genomdriva dessa villkor, även om användaren kan genomdriva dem för ett barn som har använt våra produkter i enlighet med dessa villkor.

OneTwo Diabetes kan överföra sina rättigheter och skyldigheter från dessa villkor till ett annat företag eller tredje part. Om enskilda bestämmelser i dessa villkor är eller blir ineffektiva förblir de andra bestämmelserna i dessa villkor som inte påverkas av ogiltigheten giltiga och tillämpliga.

## 14. Viktig anmärkning om minderåriga
Vid registrering bekräftar användaren att den är minst 18 år gammal och att tillämplig lag tillåter den att ingå avtal. Endast personer med laglig behörighet får registrera sig. Minderåriga är endast tillåtna med uttryckligt samtycke från en förälder/vårdnadshavare. Med registreringen förklarar föräldern/vårdnadshavaren att de har rätt att lämna in juridiskt bindande deklarationer i barnets namn.
`
