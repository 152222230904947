export const getTreatments = (treatments) => {
  if (treatments == null) return []
  let result = []
  for (let i = 0; i < treatments.length; i++) {
    result.push(treatments[i].key)
  }
  return result
}

export const getSelectableTreatments = (
  diabetesType,
  t1Treatments,
  t2Treatments
) => {
  if (!diabetesType) {
    return []
  }
  if (diabetesType == "DIABETES_TYPE1") {
    return t1Treatments
  } else {
    return t2Treatments
  }
}

export const getIsUser = (
  profile,
  profileForUserExists,
  user
) => {
  if (!profile && profileForUserExists) return false
  if (!profile && !profileForUserExists) return true

  let isUser = false
  for (let i = 0; i < profile.relatedUsers.length; i++) {
    if (profile.relatedUsers[i].userId == user.id) {
      if (profile.relatedUsers[i].relationId == 1) {
        isUser = true
      }
    }
  }
  console.log("isUser: " + isUser)
  return isUser
}
