export const terms_of_use_en = `December 14th, 2022
OneTwo Analytics AB

# Terms of use

Version: 1.1.5

## 1. Introduction
OneTwo Diabetes is a product published by the company OneTwo Analytics AB, ("OneTwo Analytics"). These Terms of Use ("Terms") refer to the use of this product, both as mobile app and on the web. The terms apply between OneTwo Analytics and you as a user when you visit our website or use any of our products or services (collectively, the "Products").

We would like to emphasize that our product should only be used to provide you with information to raise awareness about your diabetes, however, it cannot replace consultation and diagnosis by health professionals. OneTwo Diabetes provides, under no circumstances, recommendations on medical therapy or medical counseling of any kind. For questions about illness and therapy, we recommend that you contact your healthcare provider. The data may only be analyzed by you and your healthcare provider. No content – whether it is provided by OneTwo Diabetes, our partners or users - can be supplementing or replacing information from healthcare providers.

OneTwo Diabetes should not be used in an emergency. In the event of an emergency, contact nearest rescue unit or call 112.

Please read these terms and conditions carefully. Access and use of OneTwo Diabetes is conditional on your agreement to these terms and conditions. You must read, confirm, and accept all the terms and conditions contained in this document. By using our product, you accept these terms and conditions. If you have any questions related to these terms and conditions, please feel free to contact OneTwo Diabetes at [support@onetwo-diabetes.com](mailto:support@onetwo-diabetes.com).

OneTwo Analytics is a Swedish company headquartered at Kungsgatan 84, 112 27 Stockholm. The company works to improve the lives of people with diabetes and supports healthcare providers who provide care for these people. The products provide a secondary analysis of data from continuous glucose monitoring on a suitable device (phone or tablet). The product does not replace continuous real-time glucose monitoring or regular home blood glucose monitoring. For any medical decisions about your health, consult your doctor and use your continuous glucose monitor (CGM).

## 2. Changes to the terms
We explicitly reserve the right to make future changes to our Terms when we deem it to be necessary. For example, changes may be necessary to comply with legal requirements, correspond to technical and financial requirements or to satisfy the interests of our users.

Changes to our terms and conditions will be informed to our users before they take effect. Minor changes to our terms and conditions will not be communicated to our users before they take effect. We therefore recommend that all users regularly read the latest and current version of the terms and conditions.

The use of our product requires your registration at OneTwo Diabetes in our channels or where apps are provided. Once you have activated our product for the first time, you must register with an email address and password. By registering, the user agrees to enter into an agreement with OneTwo Analytics for the use of our product OneTwo Diabetes. We explicitly accept such an offer by activating your user account.

Activation is confirmed immediately after registration by allowing you to use our product. We reserve the rights to reject individual users or their offer to enter a contract without giving reasons. In such a case, all provided user data will be deleted immediately.

With your registration and activation, you acquire the non-exclusive, non-transferable but geographically unlimited right to save and use a copy of our product or app for your own purposes within the framework of these conditions. You can use our free product without a time limit. If you have questions about your time of use or its end, please contact [support@onetwo-diabetes.com](mailto:support@onetwo-diabetes.com).

Upon registration, the user confirms their approval and unlimited acceptance of the content in these terms and that the information is true, accurate, up-to-date, and complete. Users are required to regularly update their personal information. OneTwo Diabetes can change or remove any information it deems inappropriate or illegal or otherwise likely to expose OneTwo Diabetes to third-party claims. You agree that we may take steps to verify the accuracy of the information you have provided to us. We reserve the right to contact users to verify registration data and usage information.

If a user provides false, inaccurate, out-of-date or incomplete information or if we have reason to believe that the information is false, inaccurate, out of date or incomplete, OneTwo Diabetes has the right to block the specific user account with immediate effect without prior notice and prohibit the use of our product without being obliged to pay back user for any costs.

You are responsible for maintaining the security of your user account. In addition, you are fully responsible for all activities that occur under the account and all other actions taken in connection with the account.

You are responsible for taking reasonable actions to maintain the confidentiality of your username and password. You must immediately notify OneTwo Diabetes on [support@onetwo-diabetes.com](mailto:support@onetwo-diabetes.com) of unauthorized use of your information, account, or other security breaches. OneTwo Diabetes will not be responsible for any information or omissions by you, including damages of any kind that may occur because of such information or omissions.

The user must protect their user account and registration data from misuse and use with fraudulent intent. If there is even a suspicion that it has been exposed to such risks, this must be communicated to [support@onetwo-diabetes.com](mailto:support@onetwo-diabetes.com) without delay. We have the right to block the user's account if used for incorrect or fraudulent intentions.

We reserve the right to temporarily block users at any time for any important reason (according to our judgment) or to permanently exclude them from using our product.

What constitutes a major cause and whether it is considered to have occurred is assessed by OneTwo Diabetes.

## 3. User’s responsibilities
Each user has the right and obligation to use our product at their own risk and expense at appropriate technical equipment. The devices and operating systems that are compatible with the product can be found in the app stores or can be requested from [support@onetwo-diabetes.com](mailto:support@onetwo-diabetes.com) at any time.

You are obliged to use our product solely in accordance with these terms and conditions and statutory regulations and avoid any misuse. Please notify us immediately if you discover a crime against the particulars listed in this section on [support@onetwo-diabetes.com](mailto:support@onetwo-diabetes.com).

Each user is obliged to enter their registration data in a truthful manner, to keep it updated and complete and not to pass it on to any third party. Personal data must be treated confidentially, carefully managed, and backed up under your own responsibility. OneTwo Diabetes takes no responsibility or other guarantees for lost or damaged data or content.

Content such as photos, images, texts, videos, or other images may only be stored, published, transmitted or distributed in connection with our product if the user has the right to transfer or use them. In all cases, the use of racist, offensive, discriminatory, slanderous, sexual, pornographic, violent, or other illegal content of all kinds are always prohibited.

It is forbidden to decrypt, convert, decompile, or disassemble our product. Each user is obliged not to carry out any technical or electronic interference with our product or network of OneTwo Diabetes (in particular, hacking attempts, brute force attacks, the introduction of viruses, worms, Trojans, other malicious software) or any kind of attempted interference that may affect the software or hardware of the OneTwo Diabetes product and system.

## 4. OneTwo Analytics responsibilities
Unless otherwise stated in these Terms, OneTwo Analytics is responsible and provides warranties under statutory Provisions. In the case of users who are not consumers, liability for financial losses, consequential damages, lost profits, and damages resulting from a third-party are excluded. If our responsibility is limited or excluded in these terms and conditions, this also applies to substituted agents and affiliated companies in OneTwo Analytics.

Outside the scope of product liability legislation, OneTwo Analytics' liability is limited to intentional acts and gross negligence - except for personal injury. Liability for slight negligence is excluded when faced with objective justifications.

OneTwo Analytics gives no warranty that our product will be fully available without interruption and without errors or that the necessary software and hardware works without errors. Nor can we rule out the possibility that data may be tracked, recorded, or falsified by third parties during data transmission via third-party systems, especially the Internet and other telecommunications networks.

OneTwo Analytics does not offer any warranty for downloaded content or materials that users have received because of the use of our products. The user is solely responsible for any damage that may be created in their IT systems or devices or for loss of data due to downloading of materials associated with our products.

OneTwo Analytics is not responsible for third-party content such as external links, banners, other information, or promotional offers from third parties that may be placed as part of our products. If we enable access to third-party offers via messages or links, OneTwo Analytics is not responsible for the information contained in it. Resulting legal transactions with third parties lead exclusively to contractual relations between the user and the relevant third party. We do not offer anyone warranty or accept any liability for third-party products.

The use of our products does not replace consultation with healthcare providers or other medical professionals’ advice and is done exclusively at the user's own risk. This applies to the use of data that is provided as part of our products including blood glucose results, predictions, calculations, and possible recommendations. The user expressly acknowledges that such information may be defective, and OneTwo Analytics assumes no responsibility for its accuracy.

To the extent required by applicable medical device legislation governing the use of the product, the product may only be used or used exclusively according to the purpose, the specifications and application areas specified in the offer and terms of use.

## 5. Availability of our products
We provide our products in each case in accordance with existing technical, economic, operational, and organizational capabilities. OneTwo Analytics cannot exclude any interruptions, disruptions, delays, deletions, incorrect transfers, or storage errors in connection with the use of our product or communication with users. We partly offer our product in cooperation with third-party providers and therefore also depend on the availability of third-party products. Therefore, OneTwo Analytics accepts no responsibility, warranty, liability, or obligation to provide our product online all the time without interruption.

The previous paragraph also applies to restrictions on the use of our product due to force majeure, strikes, lockouts and official instructions or due to technical changes or maintenance work on the OneTwo Analytics systems. In case of disruptions or failure of our product, please contact [support@onetwo-diabetes.com](mailto:support@onetwo-diabetes.com) at any time.

## 6. Purchases and payments
Our product, OneTwo Diabetes, can be accessed or downloaded on the AppStore and Google Play and requires a user account.

We expressly reserve the right to restrict free products at any time, stop them altogether or charge a fee for them. The user has no legal right to any future free use of individuals products or parts thereof for a certain period.

OneTwo Analytics can also make special and free offers that are subject to additional conditions or restrictions. In this regard, we expressly refer to any deviations from these terms while otherwise, the entire content of these terms and conditions is still applicable. Special offers or Free offers cannot be transferred to other users.

Configurations and prices of the product are subject to change at any time, and OneTwo Analytics always has the right to change configurations, fees, prices, and quotes. You agree to such changes unless you object in writing to OneTwo Analytics within seven (7) business days of receiving a notification from OneTwo Analytics, or an invoice, containing or notifying the fee and/or price changes.

All prices are excluding VAT and you shall pay all taxes, duties, fees or other similar fees placed on OneTwo Analytics or yourself by any tax authority (other than taxes such as added to OneTwo Analytics revenue), related to your order, unless you have provided OneTwo Analytics with an appropriate resale or exemption certificate for the place of delivery, which is the place where products are used or carried out. In the event of changes in the law so that a tax is charged that is or becomes rechargeable with a consequent increased cost of OneTwo Analytics to deliver the product, whereby and to the extent that OneTwo Analytics has the right to increase its prices thereafter and with retroactive effect.

## 7. Denunciation
You can end your agreement and close your account with OneTwo Analytics at any time, starting on the last the day of your subscription period, by sending an email to [support@onetwo-diabetes.com](mailto:support@onetwo-diabetes.com). OneTwo Analytics may terminate its relationship with you or terminate at any time or discontinue the availability of the product, including use of software if you violate these terms and/or any other agreement with OneTwo Analytics. 

## 8. Third-party providers
You can find our products on our website (www.onetwo-diabetes.com) or in the app stores. In general, the terms and conditions of the relevant third-party service provider apply, over which we have no influence. These are not part of our terms and conditions or our contractual relationship with you as a user of our Products.

We work with various partners to ensure optimal use of our product. These include partners who supply us or our users directly with certain products (for example, continuous glucose meters). In these cases, we explicitly emphasize the applicable terms of business of the third-party supplier that you must accept before you can acquire one of our products through a third-party provider.

## 9. Privacy
We refer to our Privacy documentation for detailed information on how we treat your personal data including clear instructions on your rights and further how you can exercise such rights.

## 10. Copyright
The software, code, methods and systems, and content of our products are protected by copyright and competition laws and may only be used by OneTwo Analytics. Our product or parts therefore may not be copied, modified, reproduced, republished, republished, transmitted, be sold, offered for sale, resold, or used in any other way without our foregoing written consent. Users may not use trademarks, logos, other commercial ownership rights or OneTwo Diabetes trademark rights. Unless otherwise stated in these Terms all use and exploitation rights are owned exclusively by OneTwo Analytics or an affiliate company and there is no licensing of any kind for our products.

If this is required for the technical provision of our product, each user provides OneTwo Diabetes a non-exclusive, revocable but free, transferable right of exploitation and use, unlimited in terms of time and place, to the content they generate, transmit, store, or publish within our products. However, use or exploitation is excluded if this disproportionately affects the legitimate interests of the user (for example, privacy rights). At use outside of our product, if appropriate, we will indicate that the content comes from the user. OneTwo Diabetes does not claim any ownership of created content and will not to take any monitoring feature with respect to user-created content.

## 11. Changes to products
The configurations and specifications of our website, including without limitation all content available, and the product may be changed and/or updated, according to OneTwo Analytics’ assessment. Users are bound by such changes or updates.

## 12. Compensations
The user accepts full responsibility for all damages and disputes within and out of court that arise due to conflicts with other users. The user expressly admits that OneTwo Diabetes under no circumstances is responsible for the actions or omissions of other users and any damage that occurs.

In the event of a claim against OneTwo Diabetes from third parties, the user is obliged to immediately provide all information available to them in a truthful and complete manner that requires to review, defend, and prevent third-party claims.

## 13. Other
These terms and conditions and the entire legal relationship between OneTwo Diabetes and the user are subject to Swedish law, and the courts in Sweden have exclusive jurisdiction to examine any claims derived from them. However, as a consumer, the user enjoys the protection of mandatory provisions of the country in which they reside or habitually reside.

The agreement between the user and OneTwo Analytics, as set out in these Terms, is personal, which is why the user is not allowed to transfer these to another person without our prior written consent. Only the user can enforce these terms, although the user may enforce them for a child who has used our products in accordance with these terms and conditions.

OneTwo Diabetes may transfer its rights and obligations from these terms to another company or third parties. If individual provisions of these Terms are or become ineffective, the others remain the provisions of these Terms and Conditions which are not affected by the invalidity valid and applicable.

## 14. Important note about minors
Upon registration, the user confirms that they are at least 18 years old and that applicable law allows them to enter into agreements. Only people with legal authority are allowed to register. Minors are only allowed with the express consent of a parent/guardian. With the registration, the parent/guardian declare that they have the right to submit legally binding declarations in the child's name.
`
