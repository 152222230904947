import axios from "../api/axios"
import useAuth from "./useAuth"
import useProfiles from "./useProfiles"
import getProfiles from "../api/profiles"

const LOGIN_URL = "/v1/auth/login"

const useLogin = () => {
  const { setAuth } = useAuth()
  const { setProfiles } = useProfiles()

  const login = async (email, password) => {
    console.log("Logging in user " + email)

    const response = await axios.post(
      LOGIN_URL,
      JSON.stringify({ email: email, password: password }),
      {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      }
    )
    let roles = ["USER"]
    if (response?.data?.user.isSuperUser) {
      roles.push("ADMIN")
    }

    console.log(
      "User: " + response?.data?.user.email + " " + roles + " logged in"
    )

    localStorage.setItem("loggedIn", JSON.stringify(true))
    setAuth({
      user: response?.data?.user,
      roles,
      accessToken: response?.data?.auth.token,
    })
    const profiles = await getProfiles(response?.data?.auth.token)
    setProfiles(profiles)
  }
  return login
}

export default useLogin
