import axios from "./axios"

export const initOauth = async (token, profile_id) => {
  try {
    const params = { source: "web", patientId: profile_id }
    const response = await axios.get("/v1/dexcom-oauth-init-url", {
      params: params,
      //   signal: controller.signal,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
    return response.data["oauthUrl"]
  } catch (err) {
    throw err
  }
}
