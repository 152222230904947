import React, { useState } from "react"
import useProfiles from "../hooks/useProfiles"
import Navbar from "../component/Navbar"
import Footer from "../component/Footer"
import { Loading } from "../component/Loading"
import { ProfileCard } from "../component/card/ProfileCard"
import { Title } from "../component/Title"

import "./Page.css"
import { t } from "i18next"

function Home() {
  const { profiles } = useProfiles()
  const [activeCardId, setActiveCardId] = useState()

  return (
    <section id="home" className="home">
      <Navbar />
      <div className="home-container">
        <Title
          title={
            profiles.length > 1
              ? t("home.titlePlural")
              : t("home.titleSingular")
          }
        />
        <div className="home-intro">
          <p>{t("home.paragraph")}</p>
        </div>

        <div className="home-cards">
          {profiles === null ? (
            <div className="card-loading">
              <Loading color="onetwo--primary" context="loading-profiles" />
            </div>
          ) : (
            profiles.map((profile) => (
              <ProfileCard
                key={profile.id}
                profile={profile}
                isActive={profile.id === activeCardId}
                setActiveCard={setActiveCardId}
              />
            ))
          )}
          <ProfileCard
            key={0}
            isActive={activeCardId === 0}
            setActiveCard={setActiveCardId}
          />
        </div>
      </div>
      <Footer />
    </section>
  )
}

export default Home
