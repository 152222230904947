import axios from "./axios"

export const dbRequestPasswordReset = async (email) => {
  const body = { email: email, domain: "onetwo" }
  try {
    const response = await axios.post("/v1/auth/send_reset_password_email", body, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    return response.data
  } catch (err) {
    throw err
  }
}

export const dbPasswordReset = async (oob, pwd) => {
  const body = { oobCode: oob, newPassword: pwd }
  try {
    const response = await axios.post("/v1/auth/reset_password", body, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    return response.data
  } catch (err) {
    throw err
  }
}

export const dbChangePassword = async (oldPassword, newPassword, token) => {
  const body = {
    oldPassword: oldPassword,
    newPassword: newPassword,
  }
  try {
    const response = await axios.post("/v1/auth/change_password", body, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
    return
  } catch (err) {
    throw err
  }
}
