import { axiosPipeline } from "./axios"

export const getPipelineStatus = async (token, pipelineJobid) => {
  try {
    const response = await axiosPipeline.get("/status/" + pipelineJobid, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
    return response.data
  } catch (err) {
    throw err
  }
}
