import React from "react"
import { t } from "i18next"
import Navbar from "../component/Navbar"
import { Button } from "../component/Button"
import "./Page.css"

function Unauthorized() {
  return (
    <>
      <Navbar />
      <section id="unauthorized" className="unauthorized">
        <h1 className="mb-3">{t("unauthorized.title")}</h1>
        <Button buttonStyle="btn--form" buttonSize="btn--large" linkTo="/">
          {t("unauthorized.button.return")}
        </Button>
      </section>
    </>
  )
}

export default Unauthorized
