import axios from "./axios"

export const dbDeleteAccount = async (userId, token) => {
  try {
    const response = await axios.delete("/v1/user/" + userId, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
    return response.data
  } catch (err) {
    throw err.response.data.error.message
  }
}

export const dbPutUser = async (userId, userData, token) => {
  try {
    const response = await axios.put("/v1/user/" + userId, userData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
    return response.data
  } catch (err) {
    throw err.response.data.error.message
  }
}
