export const privacy_policy_en = `December 14th, 2022
OneTwo Analytics AB

# Privacy Policy

Version: 1.1.5

## 1. INTRODUCTION

OneTwo Diabetes is issued by the company OneTwo Analytics AB (“OneTwo Analytics”, “we”, “us”, etc.). It is important that the people who use our services and products and visit our website etc. (collectively, the “Products”) feel safe and are informed of how we handle the users personal data (“user data”). We aim to maintain the highest possible standard of protection of personal data. This privacy policy (“Privacy policy”) describe how we collect, use and handle information and personally identifiable information about our users.

OneTwo Analytics works to improve the lives of people with diabetes and supports caregivers who provides care for these people. We are glad to see that you are interested in our products.

## 2. RESPONSIBILITY FOR THE PROCESSING OF PERSONAL DATA

OneTwo Analytics is a Swedish company that develops and operates a set of decision support tools for people with diabetes and clinics that provide care for these patients. OneTwo Analytics is personal data controller for the processing of personal data that users register when they use our products.

### Contacts

If you have any questions or comments regarding our processing of your personal data in connection with your use of the products, you are always welcome to contact us at [https://www.onetwo-diabetes.com](https://www.onetwo-diabetes.com) or by sending an e-mail to our data protection officer on [support@onetwo-diabetes.com](mailto:support@onetwo-diabetes.com)

Contact information:

OneTwo Analytics AB

Kungsgatan 84

112 27 Stockholm

## 3. COLLECTION OF PERSONAL DATA

### 3.1 Personal data registered through your use of our products

OneTwo Analytics processes personal data about you that you register via your account. This data includes name and email address when you open a user account with us and any information that you register when you use our products. In addition, we may automatically collect and process the following information: (I) Technical information, including IP address, login information, language settings etc.; and (II) Information about your use of the app, including which features you have used and when, etc.

Information about our use of cookies is in OneTwo Analytics’ cookie policy.

## 4. WHERE YOUR PERSONAL DATA IS STORED

All personal information is stored and handled primarily within the EU / EEA. Should we decide to store data outside the EU / EEA, we would always take the necessary measures in accordance with the current privacy policy to ensure that your data is stored securely.

## 5 WHY PERSONAL DATA IS PROCESSED

### 5.1 General purposes

OneTwo Analytics processes your user data for the following purposes:

(i) to process your application or close your account,
(ii) to identify your identity and age,
(iii) to maintain accurate and up-to-date information about you,
(iv) to enable you to use our products,
(v) to analyze your use of our products and improve the products,
(vi) to administer payments,
(vii) to otherwise provide the products to you and needed support.

### 5.2 Necessary processing

If you give your consent, we will process the user data listed below in order to provide our products. If you do not consent to the necessary processing, you cannot use OneTwo Analytics’ products. You can give your consents during the registration process and manage them in the account settings. (See section 10 on withdrawal of consent).

### 5.2.1 Necessary user data

To protect your user data, our products can only be used in conjunction with a user account. To create a user account and to use our products requires and we process the following user data:

- First name
- Surname
- National id number
- Mobile phone number
- E-mail address
- Password
- Registration date
- Continuous glucose monitor that you use
- Consent status
- Device ID, manufacturer, device type, version of operating system
- Language, country, time zone
- IP address

We also collect medical data: blood sugar measurement and sensor data such as sensor value, time and time zone, date of birth, gender, diabetes type, year of onset and basic diabetes medication.

### 5.2.2 Necessary purposes

All necessary purposes for our processing are associated with providing our products.

- Registration leads to the creation of your user account with email address and password.
- The provision of our products requires you to provide CGM data voluntarily.
- Communication from OneTwo Analytics in our products or via other electronic messaging services (e.g., email) when required to support or troubleshoot our products. Use our support service, [support@onetwo-diabetes.com](mailto:support@onetwo-diabetes.com), when you need to get in touch with us. Think about what information and data you want to provide in your active communication with us - it is entirely your decision. For our part, communication with user can be necessary either via email or push notification. This is how we inform you about updates to our product and provides important security advice and help in connection with your use. This support communication - as an important part of our products - is sent to users regardless of whether they subscribe to our newsletter or not.
- Use of our products requires you to enter your CGM data. To resolve an error in the app we requires crash reports that we can use for troubleshooting purposes to determine the circumstances of the problem.
- Key data of your device and your usage behavior are registered with us, which means that we can adapt our products, e.g., process individual user information that enables relevant adaptation of the user interface.

### 5.3 Processing for product improvement

We also process your user data with the purpose of improving our products and services, which is described in more detail below.

### 5.3.1 Usage data

Activity events allow us to understand how you use our products. This makes it possible for us to see how our products are used and, for example, where menu designs can be optimized.

### 5.3.2 Purpose of product improvement

As a result of rapid technological progress, we must continuously analyze, develop, test, and improve our products and their interactions to ensure that our content benefits users in the best way. To achieve this, we conduct usability and security tests, and the knowledge obtained is incorporated into improved new versions of our products, such as the app. These improvements are also provided via regular updates.

### 5.4 Processing for marketing purposes

### 5.4.1 Newsletter

We want to send you exciting information about products beyond the contractual scope (including information from carefully selected partners) and invitations to participate in surveys or other sales promotions and marketing activities ("Newsletter").

You can choose whether you want to subscribe to our newsletter (opt-in). You can revoke yours consent at any time via the link in the newsletter or in the account settings.

### 5.4.2 Other types of marketing

Other consents, e.g., for surveys or messages, are obtained as needed when you are logged in. We always explain why we need certain data and how you can withdraw consent.

### 5.5 Use for statutory purposes

### 5.5.1 Scientific research and statistics

OneTwo Analytics is committed to the science of all aspects of diabetes. Therefore, anonymous user data can also be used for research and statistics (always in accordance with recognized ethical scientific standards) and internal analyses. This is primarily used to determine and improve efficiency in techniques to control and treat diabetes. The legal basis for this is Article 9.2 j GDPR.

### 5.5.2 Enforcement of rights

The use of personal data may also be necessary to prevent misuse by users or to assert, exercise or defend legal claims. We may be forced to disclose due to binding laws, court or official decisions and instructions, criminal investigation or in the public interest. In such cases, the storage and processing of your data is permitted by law without your consent. The legal basis for this is Article 9.2 f GDPR.

## 6. HOW LONG WE KEEP YOUR DATA

OneTwo Analytics only processes your personal data as long as it is necessary for the purposes for which the information in question is processed according to section 5 above. This means that we only keep information as long as it is necessary to be able to provide the products or fulfill our legal obligations.

## 7. THIRD PARTY

In order for OneTwo Analytics to be able to offer the products, it uses external suppliers who process personal data in some cases, for example hosting providers. These service providers process personal data as data processors ("Processors") and on behalf of the OneTwo Analytics. They only get to process the data for the purpose of providing the services requested by OneTwo Analytics, and in accordance with OneTwo Analytics’ instructions.

## 8. TRANSFER TO THIRD COUNTRIES

OneTwo Analytics does not transfer data to third countries, namely countries outside the EU / EEA. This may change in the future. However, the transfer of personal data to countries outside the EU / EEA only takes place if the transfer is legal under applicable data protection legislation.

## 9. GENERAL INFORMATION

### 9.1 Purpose limitation and security

OneTwo Analytics uses your personal data exclusively for the purposes stated in this Privacy Policy and relevant consents. We ensure that each processing is limited to the extent that is necessary for its purpose. Each treatment always guarantees sufficient security and confidentiality for your personal data. This covers protection against unauthorized and illegal processing, accidental loss, accidental destruction or damage with appropriate technical and organizational measures. We use strict internal processes, security features and encryption methods.

### 9.2 Processors

We only use processors that offer sufficient guarantees that appropriate technical and organizational measures are taken in a way that the processing of personal data meets the statutory requirements and our privacy policy. The protection of our users' rights is ensured by entering into binding agreements that meet the strict requirements of GDPR.

Third party providers appointed by OneTwo Analytics may only use other processors (subcontractors) with our prior consent. If a subcontractor does not meet the same data protection obligations and any appropriate security measures we impose on our processors, we will prohibit employing such a subcontractor.

### 9.3 Encryption, pseudonymization and anonymization

Every data transfer, without exception and by default, is encrypted during transfer. By using HTTPS (Hypertext Transfer Protocol Secure) we ensure that unauthorized third parties do not intercept your data.

For data security and minimization purposes, we also use other processes for encryption and pseudonymization of user data. Of course, this depends on the type, scope, and purpose of relevant data processing. For example, we only disclose user data that a processor needs to perform their duties.

When a contractual relationship with a processor is terminated, such processor must, according to the OneTwo Analytics’ assessment, either return all of our user data or delete it if there are no statutory storage obligations.

Data that does not require a personal reference for processing (e.g., for research and analysis) is anonymized.

## 10. YOUR RIGHTS

You have many rights related to the personal data we process about you, based on the applicable data protection legislation. You can contact us at any time to:

- Request access to and information about the personal data processed by us (right to access),
- "Right to be forgotten." Every user has the right to request the deletion of their personal data. To do so, please contact us at any time at [support@onetwo-diabetes.com](mailto:support@onetwo-diabetes.com).
- Ask us to restrict the processing of your personal data if you believe that such data is incorrect, if our processing is illegal or if we no longer need to process such data for a particular purpose unless we are required to retain such according to one or another obligation,
- Object to the processing of your personal data where the legal justification for our processing your personal data is our legitimate interest. We will comply with your request if we do not have a compelling legitimate basis for the processing that overrides your interest or rights, or if we need to process such data for possible legal claims,
- Revoke your consent via the account settings or by sending an e-mail to [support@onetwo-diabetes.com](mailto:support@onetwo-diabetes.com), and
- Request that your personal data be moved to another personal data controller by receiving your personal data, to the extent that it has been provided by you, in an electronic format which is usually used to be able to transfer them to another party (the right to data portability).

If you wish to contact us regarding any of the rights mentioned above, please contact us via our website or by sending an email to [support@onetwo-diabetes.com](mailto:support@onetwo-diabetes.com).

## 11. RIGHT TO REGISTER A COMPLAINT

If you consider that the processing of your personal data is incorrect or inconsistent with the legal requirements, you have the right to submit a complaint to the relevant data protection authority. In Sweden the relevant data protection authority is the Swedish Authority for Privacy Protection (IMY).

## 12. CONTACT

We hope that this Privacy Policy has made it clear how we handle your personal data and have answered any questions you had. But if you have any questions, you are always welcome to contact us via our website or by sending an email to [support@onetwo-diabetes.com](mailto:support@onetwo-diabetes.com). We will handle your request as soon as possible.
`
