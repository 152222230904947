import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import useAuth from "../../hooks/useAuth"
import useProfiles from "../../hooks/useProfiles"
import useLogout from "../../hooks/useLogout"

import { dbDeleteAccount } from "../../api/account"
import { dbDeleteProfile } from "../../api/profiles"

import { Title } from "../../component/Title"
import { Loading } from "../Loading"
import { FormButton } from "../FormElements"

export const DeleteAccount = () => {
  const { t } = useTranslation()
  const { profiles } = useProfiles()
  const { auth } = useAuth()

  const logout = useLogout()

  const [widgetState, setWidgetState] = useState("DELETE")
  const [errorMessage, setErrorMessage] = useState("")

  const deleteAccount = async () => {
    setWidgetState("WAITING")
    try {
      auth.user.patientRelations.forEach(async (element) => {
        await dbDeleteProfile(element.patient_id, auth.accessToken)
      })
      await dbDeleteAccount(auth.user.id, auth.accessToken)
      await logout()
    } catch (err) {
      setWidgetState("ERROR")
      if (!err?.response) {
        setErrorMessage(t("settings.deleteUser.error.NO_RESPONSE"))
      } else if ("error" in err.response.data) {
        setErrorMessage(
          t("settings.deleteUser.error." + err.response.data.error.message)
        )
      } else {
        setErrorMessage("settings.deleteUser.error.DELETE_ERROR")
      }
    }
  }

  const ConfirmDelete = () => {
    return (
      <div className="delete-account-widget">
        <p className="mb-2">{t("settings.deleteAccount.confirmation")}</p>
        <ul>
          {profiles.map((profile, index) => (
            <li key={index}>{profile.firstName + " " + profile.lastName}</li>
          ))}
        </ul>
        <div className="delete-buttons">
          <div className="delete-button">
            <FormButton
              label={t("settings.deleteAccount.button.abort")}
              callback={() => setWidgetState("DELETE")}
            />
          </div>
          <div className="delete-button">
            <FormButton
              label={t("settings.deleteAccount.button.confirm")}
              callback={deleteAccount}
              buttonClass="btn--delete"
            />
          </div>
        </div>
      </div>
    )
  }

  const Error = () => {
    return (
      <div className="delete-account-widget">
        <p className="mb-2">{errorMessage}</p>
        <div className="form-button-row">
          <FormButton
            label={t("settings.deleteAccount.button.dismiss")}
            callback={() => setWidgetState("DELETE")}
          />
        </div>
      </div>
    )
  }

  const Waiting = () => {
    return (
      <div className="delete-account-widget">
        <Loading color="white" context="delete-account" />
      </div>
    )
  }

  const DeleteAccountWidget = (
    <div className="delete-account-widget">
      <FormButton
        label={t("settings.deleteAccount.button.delete")}
        callback={() => setWidgetState("CONFIRM")}
        buttonClass="btn--delete"
      />
    </div>
  )

  return (
    <section className="settings-delete-account">
      <Title title={t("settings.deleteAccount.title")} />
      <div className="delete-account">
        <h2 className="settings-paragraph">
          {t("settings.deleteAccount.subTitle")}
        </h2>
        <p className="settings-paragraph">
          {t("settings.deleteAccount.paragraph")}
        </p>

        <div className="account-delete">
          {widgetState === "DELETE" ? (
            DeleteAccountWidget
          ) : widgetState === "CONFIRM" ? (
            <ConfirmDelete />
          ) : widgetState === "ERROR" ? (
            <Error />
          ) : (
            <Waiting />
          )}
        </div>
      </div>
    </section>
  )
}

export default DeleteAccount
