import React from "react"
import { Loading } from "../component/Loading"
import "./Page.css"

function LoadingPage() {
  return (
    <div className="loading-page">
      <Loading color="onetwo--primary" />
    </div>
  )
}

export default LoadingPage
