import { useEffect, useState, useRef } from "react"
import { useTranslation } from "react-i18next"
import useAuth from "../../hooks/useAuth"
import ProfileCardSubmit from "./ProfileCardSubmit"

import {
  THIS_YEAR,
  T1_TREATMENTS,
  T2_TREATMENTS,
  TREATMENT_ID,
  DIABETES_TYPES,
  DIABETES_TYPE_ID,
  GENDER_TYPES,
  GENDER_ID,
} from "./ProfileCardConstants"

import {
  getIsUser,
  getTreatments,
  getSelectableTreatments,
} from "./ProfileCardFormFunctions"

import { ProfileFormNewProfile } from "./ProfileCardFormElements"

import {
  FormInput,
  FormSelect,
  FormCheckBoxes,
  FormButton,
  isValidName,
  isValidNationalId,
  isValidYear,
} from "../FormElements"

import "../Form.css"

export default function ProfileCardForm({
  profile,
  profileForUserExists,
  updateProfile,
  createProfile,
  deleteProfile,
  setUploadMode,
  editMode,
  setEditMode,
}) {
  const { t } = useTranslation()
  const { auth } = useAuth()

  const firstNameRef = useRef()
  const lastNameRef = useRef()
  const nationalIdRef = useRef()
  const diagnoseYearRef = useRef()
  const diabetesTypeRef = useRef()
  const birthYearRef = useRef()
  const genderRef = useRef()

  const [firstNameFocus, setFirstNameFocus] = useState(false)
  const [lastNameFocus, setLastNameFocus] = useState(false)
  const [nationalIdFocus, setNationalIdFocus] = useState(false)
  const [diabetesTypeFocus, setDiabetesTypeFocus] = useState(false)
  const [diagnoseYearFocus, setDiagnoseYearFocus] = useState(false)
  const [birthYearFocus, setBirthYearFocus] = useState(false)
  const [genderFocus, setGenderFocus] = useState(false)

  const initIsUser = getIsUser(
    profile,
    profileForUserExists,
    auth.user
  )

  /* Card Body states */

  const isNewProfile = !profile

  const [isUser, setIsUser] = useState(initIsUser)

  const [inSubmit, setSubmit] = useState(false)
  const [submitMessage, setSubmitMessage] = useState()
  const [errorCode, setErrorCode] = useState()
  const [successCode, setSuccessCode] = useState()
  const [confirmCode, setConfirmCode] = useState()

  /* Initial values */
  const initfirstName = isUser ? auth.user.firstName : profile?.firstName

  const initLastName = isUser ? auth.user.lastName : profile?.lastName
  const initNationalId = isUser ? auth.user.nationalId : profile?.nationalId
  const initDiagnoseYear = profile
    ? profile.conditions[0].diagnoseYear
    : THIS_YEAR
  const initAllTreatments = getSelectableTreatments(
    profile?.conditions[0].key,
    T1_TREATMENTS,
    T2_TREATMENTS
  )
  const initTreatments = getTreatments(profile?.treatments)

  /* Form states */

  const showNewProfileContext = useRef(!profileForUserExists && isNewProfile)
  const [firstName, setFirstName] = useState(initfirstName)
  const [validFirstName, setValidFirstName] = useState(false)

  const [lastName, setLastName] = useState(initLastName)
  const [validLastName, setValidLastName] = useState(false)

  const [nationalId, setNationalId] = useState(initNationalId)
  const [validNationalId, setValidNationalId] = useState(false)

  const [diabetesType, setDiabetesType] = useState(profile?.conditions[0].key)
  const [validDiabetesType, setValidDiabetesType] = useState(false)

  const [diagnoseYear, setDiagnoseYear] = useState(initDiagnoseYear)
  const [validDiagnoseYear, setValidDiagnoseYear] = useState(false)

  const [birthYear, setBirthYear] = useState(profile?.birthYear)
  const [validBirthYear, setValidBirthYear] = useState(false)

  const [gender, setGender] = useState(profile?.gender.key)
  const [validGender, setValidGender] = useState(false)

  const [allTreatments, setAllTreatments] = useState(initAllTreatments)
  const [treatments, setTreatments] = useState(initTreatments)

  const [nationalityId, setNationalityId] = useState(auth.user.nationalityId)

  /* Effects (validation and state change functions) */

  useEffect(() => {
    if (isUser) {
      setFirstName(auth.user.firstName)
      setLastName(auth.user.lastName)
      setNationalId(auth.user.nationalId)
    } else {
      setFirstName(profile?.firstName)
      setLastName(profile?.lastName)
      setNationalId(profile?.nationalId)
    }
  }, [isUser])

  useEffect(() => {
    setValidFirstName(isValidName(firstName))
    // console.log("firstName valid " + validFirstName)
  }, [firstName])

  useEffect(() => {
    setValidLastName(isValidName(lastName))
  }, [lastName])

  useEffect(() => {
    const result = isValidNationalId(nationalId)
    if (nationalId != null) {
      if (result && !birthYear) {
        if (
          nationalId.substring(0, 4) > 1900 &&
          nationalId.substring(0, 4) <= THIS_YEAR
        ) {
          setBirthYear(nationalId.substring(0, 4))
        }
      }
    }
    setValidNationalId(result)
  }, [nationalId])

  useEffect(() => {
    if (diabetesType === "NOT_SET") {
      setAllTreatments()
    } else if (diabetesType === "DIABETES_TYPE1") {
      setAllTreatments(T1_TREATMENTS)
    } else {
      setAllTreatments(T2_TREATMENTS)
    }
    if (diabetesType == null) setValidDiabetesType(false)
    else if (diabetesType === "NOT_SET") setValidDiabetesType(false)
    else setValidDiabetesType(true)
    if (profile?.conditions[0].key === diabetesType)
      setTreatments(getTreatments(profile?.treatments))
    else setTreatments([])
  }, [diabetesType])

  useEffect(() => {
    if (gender == null || gender === "UNKNOWN") setValidGender(false)
    else setValidGender(true)
  }, [gender])

  useEffect(() => {
    setValidDiagnoseYear(isValidYear(diagnoseYear))
  }, [diagnoseYear])

  useEffect(() => {
    setValidBirthYear(isValidYear(birthYear))
  }, [birthYear])

  const updateTreatments = (treatment, checked) => {
    let newTreatments = treatments.filter((t) => t !== treatment)
    if (checked) newTreatments = [...newTreatments, treatment]
    setTreatments(newTreatments)
  }

  const resetFields = () => {
    setFirstName(initfirstName)
    setLastName(initLastName)
    setNationalId(initNationalId)
    setDiabetesType(profile?.conditions[0].key)
    setDiagnoseYear(initDiagnoseYear)
    setBirthYear(profile?.birthYear)
    setGender(profile?.gender.key)
    setTreatments(initTreatments)
    setAllTreatments(initAllTreatments)
  }

  const validateAll = () => {
    return (
      validFirstName &&
      validLastName &&
      validNationalId &&
      validBirthYear &&
      validDiagnoseYear &&
      validDiabetesType &&
      validGender
    )
  }

  const getProfileData = () => {
    const profileData = {
      firstName: firstName,
      lastName: lastName,
      nationalId: nationalId,
      birthYear: birthYear,
      gender: { id: GENDER_ID[gender] },
      conditions: [
        { id: DIABETES_TYPE_ID[diabetesType], diagnoseYear: diagnoseYear },
      ],
      treatments: treatments.map((treatment) => ({
        id: TREATMENT_ID[treatment],
      })),
    }
    // if (!isNewProfile) profileData["id"] = profile.id
    return profileData
  }

  const upload = () => {
    setUploadMode(true)
  }

  // Clear submit message when submit turns false
  useEffect(() => {
    if (!inSubmit) setSubmitMessage()
  }, [inSubmit])

  const create = async () => {
    const validForm = validateAll()
    if (!validForm) {
      setErrorCode("ERROR_IN_FIELDS")
      setSubmit(true)
      return
    }

    const profileData = {
      patient: getProfileData(),
      user_relation: {
        user_id: auth.user.id,
        relation: { id: isUser ? 1 : 2 },
      },
    }
    setSubmitMessage("CREATING_PROFILE")
    setSubmit(true)
    try {
      await createProfile(profileData)
      setSubmit(false)
    } catch (error) {
      if (error === "PATIENT_DOES_ALREADY_EXIST") {
        setNationalIdFocus(true)
        setValidNationalId(false)
      }
      setErrorCode(error)
    }
  }

  const update = async () => {
    const validForm = validateAll()
    if (!validForm) {
      setErrorCode("ERROR_IN_FIELDS")
      setSubmit(true)
      return
    }

    const profileData = {
      patient: getProfileData(),
    }
    setSubmitMessage("UPDATING_PROFILE")
    setSubmit(true)
    try {
      await updateProfile(profile.id, profileData)
      setSuccessCode("update")
    } catch (error) {
      if (error === "PATIENT_DOES_ALREADY_EXIST") {
        setNationalIdFocus(true)
        setValidNationalId(false)
      }
      setErrorCode(error)
    }
  }

  const confirmDelete = async () => {
    setConfirmCode("delete")
    setSubmit(true)
  }

  const triggerDelete = async () => {
    setConfirmCode()
    setSubmitMessage("DELETING_PROFILE")
    try {
      await deleteProfile(profile.id)
      dismiss()
    } catch (error) {
      setErrorCode(error)
    }
  }

  const dismiss = (action) => {
    if (action === "delete") {
      triggerDelete()
      return
    }
    setEditMode(false)
    setErrorCode()
    setSuccessCode()
    setConfirmCode()
    setSubmit(false)
  }

  return (
    <>
      <div>
        {showNewProfileContext.current && (
          <ProfileFormNewProfile
            isUser={isUser}
            setIsUser={setIsUser}
            userFullName={auth.user.firstName + " " + auth.user.lastName}
          />
        )}
        <form className={inSubmit ? "invisible" : ""}>
          <ProfileCardSubmit
            inSubmit={inSubmit}
            message={submitMessage}
            errorCode={errorCode}
            successCode={successCode}
            confirmCode={confirmCode}
            dismiss={dismiss}
          />
          <FormInput
            id="firstName"
            label={t("home.profile.firstName")}
            value={firstName}
            disabled={!editMode || isUser}
            valid={validFirstName}
            reference={firstNameRef}
            type="text"
            setValue={setFirstName}
            setFocus={setFirstNameFocus}
          />
          <FormInput
            id="lastName"
            label={t("home.profile.lastName")}
            value={lastName}
            disabled={!editMode || isUser}
            valid={validLastName}
            reference={lastNameRef}
            type="text"
            setValue={setLastName}
            setFocus={setLastNameFocus}
          />
          {nationalityId === 46 && (
            <FormInput
              id="nationalId"
              label={t("home.profile.nationalId")}
              value={nationalId}
              disabled={!editMode || isUser}
              valid={validNationalId}
              reference={nationalIdRef}
              type="text"
              setValue={setNationalId}
              setFocus={setNationalIdFocus}
            />)}
          <FormSelect
            id="diabetesType"
            label={t("home.profile.diabetesType")}
            value={diabetesType}
            fallbackValue="NOT_SET"
            disabled={!editMode}
            valid={validDiabetesType}
            reference={diabetesTypeRef}
            setValue={setDiabetesType}
            setFocus={setDiabetesTypeFocus}
            options={DIABETES_TYPES}
          />
          <FormInput
            id="diagnoseYear"
            label={t("home.profile.diagnoseYear")}
            value={diagnoseYear}
            disabled={!editMode}
            valid={validDiagnoseYear}
            reference={diagnoseYearRef}
            type="number"
            setValue={setDiagnoseYear}
            setFocus={setDiagnoseYearFocus}
          />
          <FormInput
            id="birthYear"
            label={t("home.profile.birthYear")}
            value={birthYear}
            disabled={!editMode}
            valid={validBirthYear}
            reference={birthYearRef}
            type="number"
            setValue={setBirthYear}
            setFocus={setBirthYearFocus}
          />
          <FormSelect
            id="gender"
            label={t("home.profile.gender")}
            value={gender}
            fallbackValue="UNKNOWN"
            disabled={!editMode}
            valid={validGender}
            reference={genderRef}
            setValue={setGender}
            setFocus={setGenderFocus}
            options={GENDER_TYPES}
          />
          <FormCheckBoxes
            id="treatment"
            label={t("home.profile.treatment")}
            values={treatments}
            disabled={!editMode}
            setValue={updateTreatments}
            optionList={allTreatments}
          />
        </form>
      </div>
      <div className="card-footer mt-2">
        {!inSubmit && editMode && !isNewProfile && (
          <FormButton
            label={t("home.profile.button.close")}
            callback={() => {
              resetFields()
              setEditMode(false)
            }}
          />
        )}
        {!inSubmit && editMode && (
          <FormButton
            label={t(
              "home.profile.button." + (isNewProfile ? "create" : "save")
            )}
            callback={isNewProfile ? create : update}
          />
        )}

        {!inSubmit && !editMode && (
          <>
            <FormButton
              label={t("home.profile.button.delete")}
              buttonClass={"btn--danger"}
              callback={confirmDelete}
            />
            <FormButton
              label={t("home.profile.button.data")}
              callback={upload}
            />
            <FormButton
              label={t("home.profile.button.edit")}
              callback={() => {
                setEditMode(true)
              }}
            />
          </>
        )}
      </div>
    </>
  )
}
