import React from "react"
import "./Button.css"
import { Link } from "react-router-dom"

const STYLES = [
  "btn--primary",
  "btn--form",
  "btn--form--text",
  "btn--outline",
  "btn--delete",
  "btn--danger",
  "btn--light",
  "btn--green",
  "btn--green-edge",
  "btn--primary-disabled",
  "btn--dropdown",
  "none",
]

const SIZES = ["btn--xsmall", "btn--small", "btn--medium", "btn--large"]

export const Button = ({
  children,
  type,
  onClick,
  buttonStyle,
  buttonSize,
  linkTo,
  id,
}) => {
  const checkButtonStyle = STYLES.includes(buttonStyle)
    ? buttonStyle
    : STYLES[0]

  const checkButtonSize = SIZES.includes(buttonSize) ? buttonSize : SIZES[0]

  return linkTo ? (
    <Link to={linkTo}>
      <button
        id={id}
        className={`btn ${checkButtonStyle} ${checkButtonSize}`}
        onClick={onClick}
        type={type}
      >
        {children}
      </button>
    </Link>
  ) : (
    <button
      id={id}
      className={`btn ${checkButtonStyle} ${checkButtonSize}`}
      onClick={onClick}
      type={type}
    >
      {children}
    </button>
  )
}
