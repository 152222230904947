import React, { useState, useRef, useEffect } from "react"
import { useTranslation } from "react-i18next"
import useAuth from "../../hooks/useAuth"

import { dbChangePassword } from "../../api/passwords"

import { Title } from "../../component/Title"

import { FormInput, FormButton, isValidPassword } from "../FormElements"

export const ChangePassword = () => {
  const { t } = useTranslation()
  const { auth } = useAuth()

  const [widgetState, setWidgetState] = useState("READY")
  const [errorMessage, setErrorMessage] = useState("")

  const oldPasswordRef = useRef(null)
  const newPasswordRef = useRef(null)
  const confirmNewPasswordRef = useRef(null)

  const [oldPassword, setOldPassword] = useState()
  const [newPassword, setNewPassword] = useState()
  const [confirmNewPassword, setConfirmNewPassword] = useState()

  const validateForm = () => {
    if (!isValidPassword(newPassword)) {
      setErrorMessage(t("settings.changePassword.error.INVALID_PASSWORD"))
      return false
    } else if (newPassword != confirmNewPassword) {
      setErrorMessage(t("settings.changePassword.error.PASSWORD_MATCH"))
      return false
    }
    return true
  }

  const resetFields = () => {
    setOldPassword()
    setNewPassword()
    setConfirmNewPassword()
  }

  useEffect(() => {
    if (errorMessage != "") setWidgetState("ERROR")
  }, [errorMessage])

  useEffect(() => {
    if (widgetState != "ERROR") setErrorMessage("")
  }, [widgetState])

  const changePassword = async (e) => {
    e.preventDefault()
    if (!validateForm()) return

    try {
      await dbChangePassword(oldPassword, newPassword, auth.accessToken)
      resetFields()
      setWidgetState("DONE")
    } catch (err) {
      if (!err?.response) {
        setErrorMessage(t("settings.changePassword.error.NO_RESPONSE"))
      } else if ("error" in err.response.data) {
        setErrorMessage(
          t("settings.changePassword.error." + err.response.data.error.message)
        )
      } else {
        setErrorMessage(t("settings.changePassword.error.UPDATE_ERROR"))
      }
    }
  }

  const Error = () => {
    return (
      <div className="change-password-widget">
        <p className="mb-2">{errorMessage}</p>
        <div className="form-button-row">
          <FormButton
            label={t("settings.changePassword.button.dismiss")}
            callback={() => setWidgetState("READY")}
          />
        </div>
      </div>
    )
  }

  const Done = () => {
    return (
      <div className="change-password-widget">
        <p className="mb-2">{t("settings.changePassword.success")}</p>
        <div className="form-button-row">
          <FormButton
            label={t("settings.changePassword.button.dismiss")}
            callback={() => setWidgetState("READY")}
          />
        </div>
      </div>
    )
  }

  const ChangePassWordForm = (
    <form onSubmit={changePassword}>
      <FormInput
        id="oldPassword"
        label={t("settings.changePassword.label.oldPassword")}
        value={oldPassword}
        disabled={false}
        valid={true}
        reference={oldPasswordRef}
        type="password"
        setValue={(e) => {
          setOldPassword(e)
        }}
        setFocus={(f) => {}}
      />
      <FormInput
        id="newPassword"
        label={t("settings.changePassword.label.newPassword")}
        value={newPassword}
        disabled={false}
        valid={isValidPassword(newPassword)}
        reference={newPasswordRef}
        type="password"
        setValue={(e) => {
          setNewPassword(e)
        }}
        setFocus={() => {}}
      />
      <FormInput
        id="confirmNewPassword"
        label={t("settings.changePassword.label.confirmNewPassword")}
        value={confirmNewPassword}
        disabled={false}
        valid={newPassword == confirmNewPassword}
        reference={confirmNewPasswordRef}
        type="password"
        setValue={(e) => {
          setConfirmNewPassword(e)
        }}
        setFocus={() => {}}
      />
      <div className="form-button-row right">
        <FormButton
          label={t("settings.changePassword.button.changePassword")}
        />
      </div>
    </form>
  )

  return (
    <section className="settings-change-password">
      <Title title={t("settings.changePassword.title")} />
      <div className="change-password">
        <h2 className="settings-paragraph">
          {t("settings.changePassword.subTitle")}
        </h2>
        <p className="settings-paragraph">
          {t("settings.changePassword.paragraph")}
        </p>
        {widgetState == "READY" ? (
          ChangePassWordForm
        ) : widgetState == "ERROR" ? (
          <Error />
        ) : (
          <Done />
        )}
      </div>
    </section>
  )
}
