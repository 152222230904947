import { t } from "i18next"
import { Button } from "../../Button"
import { faLink, faLinkSlash } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { SmallLoading } from "../../Loading"

export const DataButton = ({
  isActive,
  hasConnection,
  makePrimary,
  label,
  callback,
  upload = false,
  isLoading,
}) => {
  return (
    <div className="data-button-wrapper">
      <Button
        buttonSize="btn--xsmall"
        buttonStyle={isActive ? "btn--green" : "btn--form"}
        onClick={callback}
      >
        {!upload ? (
          <FontAwesomeIcon
            icon={hasConnection ? faLink : faLinkSlash}
            className="data-button-icon"
          />
        ) : null}
        {isLoading ? <SmallLoading /> : label}
      </Button>
      {!isActive && !upload && hasConnection ? (
        <Button buttonStyle="btn--form--text" onClick={makePrimary}>
          {t("home.data.primary")}
        </Button>
      ) : null}
    </div>
  )
}
