import onetwoLogo from "./images/otd.svg"
import ce from "./images/ce.svg"
import facebook from "./images/facebook.svg"
import instagram from "./images/instagram.svg"
import onetwo_analytics_logo from "./images/onetwo-analytics.svg"


export const assets = {
  onetwoLogo,
  ce,
  facebook,
  instagram,
  onetwo_analytics_logo
}
