import { createContext, useState } from "react"

const ProfilesContext = createContext({})

export const ProfilesProvider = ({ children }) => {
  const [profiles, setProfiles] = useState()
  // console.log("Ran ProfilesProvider with Profiles value" + JSON.stringify(Profiles));
  return (
    <ProfilesContext.Provider value={{ profiles, setProfiles }}>
      {children}
    </ProfilesContext.Provider>
  )
}

export default ProfilesContext
