import React from "react"
import { useTranslation } from "react-i18next"

export const ProfileFormNewProfile = ({ isUser, setIsUser, userFullName }) => {
  const { t } = useTranslation()

  return (
    <>
      <div className="card-new-profile">
        <div className="card-new-profile-description">
          {t("home.profile.whois")}
        </div>
        <div className="card-new-profile-context">
          <i
            onClick={() => {
              setIsUser(true)
            }}
            className={`onetwo-icon card-new-profile-checkmark ${
              isUser ? "checked" : ""
            }`}
          >
            {t("icons.checkmark")}
          </i>
          <p>{t("home.profile.itsme", { userFullName: userFullName })}</p>
        </div>
        <div className="card-new-profile-context">
          <i
            onClick={() => {
              setIsUser(false)
            }}
            className={`onetwo-icon card-new-profile-checkmark ${
              !isUser ? "checked" : ""
            }`}
          >
            {t("icons.checkmark")}
          </i>
          <p>{t("home.profile.caretaker")}</p>
        </div>
      </div>
    </>
  )
}
