import axios from "axios"

export const BASE_URL = process.env.REACT_APP_DDA_API_HOST
const PIPELINE_URL = process.env.REACT_APP_DDA_PIPELINE_HOST

export default axios.create({
  baseURL: BASE_URL,
})

export const axiosPipeline = axios.create({
  baseURL: PIPELINE_URL,
})

export const axiosPrivate = axios.create({
  baseURL: BASE_URL,
  headers: { "Content-Type": "application/json" },
  withCredentials: true,
})
