import React, { Suspense } from "react"
import ReactDOM from "react-dom/client"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"

import { AuthProvider } from "./context/AuthProvider"
import { ProfilesProvider } from "./context/ProfilesProvider"

import App from "./App"
import "./index.css"

import "./i18n"

const root = ReactDOM.createRoot(document.getElementById("root"))

root.render(
  <Suspense fallback={<div>Loading language...</div>}>
    <Router>
      <ProfilesProvider>
        <AuthProvider>
          <Routes>
            <Route path="/*" element={<App />}></Route>
          </Routes>
        </AuthProvider>
      </ProfilesProvider>
    </Router>
  </Suspense>
)
